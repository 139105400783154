import { CircularProgress, Theme } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import React, { PureComponent } from "react";

import { AdminsState } from "../features/admins";
import { Admin } from "../features/admins/models";
import { UsersState } from "../features/users";
import { User } from "../features/users/models";
import {
  borderColor,
  TableCellCentered,
  TableCellCommon,
  TableCommon,
} from "./Instances";
import UsersRow from "./UsersRow";

import "./Users.css";

export type UsersProps = {
  usersState: UsersState;
  adminsState: AdminsState;
  makeAdmin: (sub: string) => void;
  revokeAdmin: (sub: string) => void;
};

export type OwnUsersState = {};

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    table: {
      ...TableCommon,
    },
    tableHeadRow: {
      /* height: tableRowHeight */
    },
    tableHeader: {
      ...TableCellCommon,
      position: "sticky",
      top: 0,
      zIndex: 10,
      backgroundColor: "#f3f3f3",
      boxShadow: `inset 0 1px 0 ${borderColor}, inset 0 -1px 0 ${borderColor}`,
      color: "rgba(0, 0, 0, 0.75)",
      whiteSpace: "nowrap",
    },
    centered: { ...TableCellCentered },
  });

/**
 * Compare function for sorting users table.
 * Sort by username.
 */
function compareUsers(a: User, b: User) {
  return a.username.toUpperCase().localeCompare(b.username.toUpperCase());
}

/**
 * Compare function for sorting admins table.
 * Sort by sub.
 */
function compareAdmins(a: Admin, b: Admin) {
  return a.sub.toUpperCase().localeCompare(b.sub.toUpperCase());
}

class Users extends PureComponent<
  UsersProps & WithStyles<typeof styles>,
  OwnUsersState
> {
  public state: OwnUsersState = {};

  public render() {
    const {
      usersState,
      adminsState,
      makeAdmin,
      revokeAdmin,
      classes,
    } = this.props;

    const usersSorted: User[] = [];
    Object.keys(usersState.byUsername).forEach((username) => {
      const user = usersState.byUsername[username];
      usersSorted.push(user);
    });
    usersSorted.sort(compareUsers);

    const orphanedAdminsSorted: Admin[] = [];
    Object.keys(adminsState.bySub).forEach((sub) => {
      const admin = adminsState.bySub[sub];
      const userIndex = usersSorted.findIndex((user) => user.sub === sub);
      if (userIndex === -1) {
        orphanedAdminsSorted.push({
          sub,
          isRevokingAdmin: admin.isRevokingAdmin,
        });
      }
    });
    orphanedAdminsSorted.sort(compareAdmins);

    return (
      <div className="Users">
        <Table classes={{ root: classes.table }}>
          <TableHead>
            <TableRow
              classes={{
                root: classes.tableHeadRow,
              }}
            >
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader),
                }}
              >
                subject
              </TableCell>
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader, classes.centered),
                }}
              >
                admin
              </TableCell>
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader, classes.centered),
                }}
              />
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader),
                }}
              >
                username
              </TableCell>
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader),
                }}
              >
                email
              </TableCell>
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader),
                }}
              >
                organizations
              </TableCell>
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader),
                }}
              >
                created date
              </TableCell>
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader),
                }}
              >
                last signed in
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersState.hasFetchedInitialUsers &&
              orphanedAdminsSorted.length > 0 &&
              orphanedAdminsSorted.map((admin) => {
                return (
                  <UsersRow
                    key={`${admin.sub}`}
                    user={{
                      sub: admin.sub,
                      email: "",
                      username: "",
                      createdDate: -1,
                      lastSignedIn: -1,
                      organizations: [],
                      hasFetchedAdmin: true,
                      isAdmin: true,
                      isMakingAdmin: false,
                    }}
                    isRevokingAdmin={admin.isRevokingAdmin}
                    makeAdmin={null}
                    revokeAdmin={revokeAdmin}
                  />
                );
              })}
            {usersState.hasFetchedInitialUsers &&
              usersSorted
                .filter((user) => user.organizations === null)
                .map((user) => {
                  return (
                    <UsersRow
                      key={`${user.username}`}
                      user={user}
                      isRevokingAdmin={this.getIsRevokingAdmin(user)}
                      makeAdmin={makeAdmin}
                      revokeAdmin={revokeAdmin}
                    />
                  );
                })}
            {usersState.hasFetchedInitialUsers &&
              usersSorted
                .filter((user) => user.organizations !== null)
                .map((user) => {
                  return (
                    <UsersRow
                      key={`${user.username}`}
                      user={user}
                      isRevokingAdmin={this.getIsRevokingAdmin(user)}
                      makeAdmin={makeAdmin}
                      revokeAdmin={revokeAdmin}
                    />
                  );
                })}
          </TableBody>
        </Table>
        {!usersState.hasFetchedInitialUsers && (
          <div className="Users-progress">
            <CircularProgress size={64} />
          </div>
        )}
      </div>
    );
  }

  private getAdminForUser = (user: User): Admin => {
    return this.props.adminsState.bySub[user.sub];
  };

  private getIsRevokingAdmin = (user: User): boolean => {
    const admin = this.getAdminForUser(user);
    return admin ? admin.isRevokingAdmin : false;
  };
}

export default withStyles(styles)(Users);
