import { CircularProgress, Theme, Tooltip } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ErrorIcon from "@material-ui/icons/Error";
import classNames from "classnames";
import React, { PureComponent } from "react";

import { Instance } from "../features/instances/models";
import { Organization } from "../features/organizations/models";
import ProgressButton from "./core/ProgressButton";
import { TableCellCentered, TableCellCommon } from "./Instances";

import "./Instances.css";

export type InstancesRowProps = {
  instance: Instance;
  organization: Organization;
  isSameAsPreviousOrg: boolean;
  isOutdated: boolean;
  handleMakeProduction: (organization: string, instanceId: string) => void;
  handleInstanceDelete: (instanceId: string) => void;
};

export type InstancesRowState = {};

export const circularProgressSize = 12;

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    instancesTableBodyRow: {
      /* height: tableRowHeight */
    },
    instancesTableCell: {
      ...TableCellCommon,
      borderTop: "solid 1px #e0e0e0",
      borderBottom: "none",
      fontSize: "0.75rem",
      whiteSpace: "nowrap"
    },
    instancesTableCellNoBorder: { borderTop: "none" },
    centered: { ...TableCellCentered },
    right: { textAlign: "right" },
    rightWithPadding: {
      textAlign: "right",
      paddingRight: 4
    },
    instancesTableButton: {
      fontSize: "0.9em",
      minHeight: 24
    },
    deleteProdButton: {
      color: palette.getContrastText(red[700]),
      backgroundColor: red[700],
      "&:hover": {
        backgroundColor: red[900]
      }
    },
    iconCell: {
      paddingLeft: 0
    },
    errorIcon: {
      color: red[700],
      marginRight: 15,
      position: "relative"
    },
    colProd: {
      minWidth: 134
    },
    colEc2State: {
      minWidth: 58
    },
    colPblicIpAddress: {
      minWidth: 89
    }
  });

class InstancesRow extends PureComponent<
  InstancesRowProps & WithStyles<typeof styles>,
  InstancesRowState
> {
  public state: InstancesRowState = {};

  public render() {
    const {
      instance,
      organization,
      isSameAsPreviousOrg,
      isOutdated,
      classes
    } = this.props;
    const {} = this.state;

    // The prod column is complicated, so do the logic here.
    let prodValue: JSX.Element | string = "";
    if (instance.hasFetchedEc2Instance && instance.hasFetchedDeployment) {
      if (instance.isProduction) {
        if (organization.productionStatus === "pending") {
          // Even though this instance is production, another instance may be targeted
          // for production, so show in progress.
          prodValue = <CircularProgress size={circularProgressSize} />;
        } else {
          prodValue = "prod";
        }
      } else {
        if (instance.ec2InstanceState === "running") {
          prodValue = (
            <ProgressButton
              variant="contained"
              color="primary"
              size="small"
              classes={{
                root: classes.instancesTableButton
              }}
              disabled={instance.isPendingDelete}
              isPending={organization.productionStatus === "pending"}
              didSucceed={false}
              onClick={this.handleMakeProduction}
            >
              Make production
            </ProgressButton>
          );
        } else {
          // Orphanced instances, those with no organization, don't need to show anything.
          if (organization) {
            // TODO You know what, make <CircularProgress> a global variable somewhere and use it everywhere.
            prodValue = <CircularProgress size={circularProgressSize} />;
          }
        }
      }
    } else {
      prodValue = <CircularProgress size={circularProgressSize} />;
    }

    // The ec2 state column is complicated, so do the logic here.
    let ec2StateValue: JSX.Element | string = "";
    if (instance.hasFetchedEc2Instance) {
      // Orphaned instances (i.e. those with no organization) should show blank,
      // so get ignored here, using the default value.
      if (organization) {
        if (instance.ec2InstanceState) {
          ec2StateValue = instance.ec2InstanceState;
        } else {
          ec2StateValue = <CircularProgress size={circularProgressSize} />;
        }
      }
    } else {
      ec2StateValue = <CircularProgress size={circularProgressSize} />;
    }

    return (
      <TableRow
        classes={{
          root: classNames(classes.instancesTableBodyRow)
        }}
      >
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : ""
            )
          }}
        >
          {}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : ""
            )
          }}
        >
          {instance.instanceId}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : "",
              classes.centered,
              classes.colProd
            )
          }}
        >
          {prodValue}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : ""
            )
          }}
        >
          {instance.instanceState ? (
            instance.instanceState.toString().toLowerCase()
          ) : (
            <CircularProgress size={circularProgressSize} />
          )}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : "",
              classes.colEc2State
            )
          }}
        >
          {ec2StateValue}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : "",
              classes.colPblicIpAddress
            )
          }}
        >
          {instance.hasFetchedEc2Instance ? (
            instance.publicIpAddress
          ) : (
            <CircularProgress size={circularProgressSize} />
          )}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : ""
            )
          }}
        >
          {instance.stripeVersion}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : "",
              classes.rightWithPadding
            )
          }}
        >
          {instance.edgeVersion}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : "",
              classes.iconCell
            )
          }}
        >
          {isOutdated && (
            <Tooltip
              placement="right"
              title="This edge node is outdated"
              disableFocusListener={true}
            >
              <ErrorIcon classes={{ root: classes.errorIcon }} />
            </Tooltip>
          )}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : "",
              classes.centered
            )
          }}
        >
          {instance.launcherVersion}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : "",
              classes.centered
            )
          }}
        >
          {instance.apiVersion}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : ""
            )
          }}
        >
          {instance.createdDate
            ? new Date(instance.createdDate).toLocaleString()
            : ""}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.instancesTableCell,
              isSameAsPreviousOrg ? classes.instancesTableCellNoBorder : "",
              classes.right
            )
          }}
        >
          <ProgressButton
            variant="contained"
            color="primary"
            size="small"
            classes={{
              root: classNames(
                classes.instancesTableButton,
                instance.isProduction && classes.deleteProdButton
              )
            }}
            disabled={false}
            isPending={instance.isPendingDelete}
            didSucceed={false}
            onClick={this.handleInstanceDelete}
          >
            Delete
          </ProgressButton>
        </TableCell>
      </TableRow>
    );
  }

  private handleMakeProduction = () => {
    this.props.handleMakeProduction(
      this.props.instance.organization!,
      this.props.instance.instanceId
    );
  };

  private handleInstanceDelete = () => {
    this.props.handleInstanceDelete(this.props.instance.instanceId);
  };
}

export default withStyles(styles)(InstancesRow);
