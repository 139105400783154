import { action } from "typesafe-actions";

import { DomainResponse, DomainUsageResponse } from "../../services/api";
import {
  FETCH_DOMAIN_USAGE,
  FETCH_DOMAIN_USAGE_FAILURE,
  FETCH_DOMAIN_USAGE_SUCCESS,
  FETCH_DOMAINS,
  FETCH_DOMAINS_FAILURE,
  FETCH_DOMAINS_SUCCESS
} from "./constants";

export const fetchDomains = () => action(FETCH_DOMAINS);
export const fetchDomainsSuccess = (domainsResponse: DomainResponse[]) =>
  action(FETCH_DOMAINS_SUCCESS, domainsResponse);
export const fetchDomainsFailure = (error: any) =>
  action(FETCH_DOMAINS_FAILURE, { error });

export const fetchDomainUsage = (domainName: string) =>
  action(FETCH_DOMAIN_USAGE, domainName);
export const fetchDomainUsageSuccess = (
  domainName: string,
  domainUsageResponse: DomainUsageResponse
) => action(FETCH_DOMAIN_USAGE_SUCCESS, { domainName, domainUsageResponse });
export const fetchDomainUsageFailure = (domainName: string, error: any) =>
  action(FETCH_DOMAIN_USAGE_FAILURE, { domainName, error });
