export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const FETCH_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAILURE = "FETCH_ORGANIZATIONS_FAILURE";

export const LAUNCH_NEW_INSTANCE = "LAUNCH_NEW_INSTANCE";
export const LAUNCH_NEW_INSTANCE_SUCCESS = "LAUNCH_NEW_INSTANCE_SUCCESS";
export const LAUNCH_NEW_INSTANCE_FAILURE = "LAUNCH_NEW_INSTANCE_FAILURE";

export const FETCH_ORGANIZATION_USAGE = "FETCH_ORGANIZATION_USAGE";
export const FETCH_ORGANIZATION_USAGE_SUCCESS =
  "FETCH_ORGANIZATION_USAGE_SUCCESS";
export const FETCH_ORGANIZATION_USAGE_FAILURE =
  "FETCH_ORGANIZATION_USAGE_FAILURE";

export const FETCH_ORGANIZATIONS_AND_DEPLOYMENTS_SUCCESS =
  "FETCH_ORGANIZATIONS_AND_DEPLOYMENTS_SUCCESS";
export const FETCH_ORGANIZATIONS_AND_EDGE_CONFIGS_SUCCESS =
  "FETCH_ORGANIZATIONS_AND_EDGE_CONFIGS_SUCCESS";
