import { CircularProgress, Theme } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import React, { PureComponent } from "react";

import { DomainsState } from "../features/domains";
import { Domain } from "../features/domains/models";
import { OrganizationsState } from "../features/organizations";
import DomainsRow from "./DomainsRow";
import {
  borderColor,
  TableCellCentered,
  TableCellCommon,
  TableCommon
} from "./Instances";

import "./Domains.css";

export type DomainsProps = DomainsState & {
  organizationsState: OrganizationsState;
  fetchOrganizationUsage: (organization: string, numDomains: number) => void;
};

export type OwnDomainsState = {};

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    table: {
      ...TableCommon
    },
    tableHeadRow: {
      /* height: tableRowHeight */
    },
    tableHeader: {
      ...TableCellCommon,
      position: "sticky",
      top: 0,
      zIndex: 10,
      backgroundColor: "#f3f3f3",
      boxShadow: `inset 0 1px 0 ${borderColor}, inset 0 -1px 0 ${borderColor}`,
      color: "rgba(0, 0, 0, 0.75)"
    },
    centered: { ...TableCellCentered },
    org: {
      minWidth: 90
    },
    button: {
      minWidth: 90
    },
    domain: {
      minWidth: 200
    },
    usage: {
      width: "100%"
    }
  });

/**
 * Compare function for sorting domains table.
 * Sort by organization, then domain.
 */
function compareDomains(a: Domain, b: Domain) {
  const aOrg = a.organization ? a.organization.toLowerCase() : "";
  const bOrg = b.organization ? b.organization.toLowerCase() : "";
  if (aOrg < bOrg) {
    return -1;
  } else if (aOrg > bOrg) {
    return 1;
  } else {
    // From https://stackoverflow.com/a/45138781/1360592
    return a.domainName.localeCompare(b.domainName);
  }
}

class Domains extends PureComponent<
  DomainsProps & WithStyles<typeof styles>,
  OwnDomainsState
> {
  public state: OwnDomainsState = {};

  private prevOrg: string | null = null;

  public render() {
    const {
      domains,
      hasFetchedInitialDomains,
      organizationsState,
      classes
    } = this.props;

    return (
      <div className="Domains">
        <Table classes={{ root: classes.table }}>
          <TableHead>
            <TableRow
              classes={{
                root: classes.tableHeadRow
              }}
            >
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader, classes.org)
                }}
              >
                org
              </TableCell>
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader, classes.domain)
                }}
              >
                domain
              </TableCell>
              <TableCell
                classes={{
                  root: classNames(classes.tableHeader, classes.usage)
                }}
              >
                usage
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hasFetchedInitialDomains &&
              organizationsState.hasFetchedInitialOrganizations &&
              domains.sort(compareDomains).map(domain => {
                const isSameAsPreviousOrg =
                  domain.organization === this.prevOrg;
                this.prevOrg = domain.organization;
                const organization =
                  organizationsState.byName[domain.organization];

                return (
                  <DomainsRow
                    key={`${domain.organization}-${domain.domainName}`}
                    domain={domain}
                    organization={organization}
                    isSameAsPreviousOrg={isSameAsPreviousOrg}
                    fetchOrganizationUsage={this.fetchOrganizationUsage}
                  />
                );
              })}
          </TableBody>
        </Table>
        {!(
          hasFetchedInitialDomains &&
          organizationsState.hasFetchedInitialOrganizations
        ) && (
          <div className="Domains-progress">
            <CircularProgress size={64} />
          </div>
        )}
      </div>
    );
  }

  private fetchOrganizationUsage = (organizationName: string) => {
    const numDomains = this.props.domains.filter(
      d => d.organization === organizationName
    ).length;
    this.props.fetchOrganizationUsage(organizationName, numDomains);
  };
}

export default withStyles(styles)(Domains);
