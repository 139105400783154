import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import Domains, { DomainsProps } from "../components/Domains";
import { organizationsActions } from "../features/organizations";
import { RootState } from "../store";

const OrganizationsContainer = (props: DomainsProps) => {
  return <Domains {...props} />;
};

const mapStateToProps = (state: RootState) => {
  return {
    domains: state.domains.domains,
    hasFetchedInitialDomains: state.domains.hasFetchedInitialDomains,
    organizationsState: state.organizations
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  fetchOrganizationUsage: (organizationName: string, numDomains: number) => {
    dispatch(
      organizationsActions.fetchOrganizationUsage(organizationName, numDomains)
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationsContainer);
