import { combineEpics, Epic } from "redux-observable";
import { debounceTime, ignoreElements, tap } from "rxjs/operators";

import { Services } from "../../services";
import { RootAction, RootState } from "../../store";

const SAVE_DELAY = 1000;

// persist state in local storage every 1s
const saveStateInLocalStorage: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, store$, services) =>
  action$.pipe(
    debounceTime(SAVE_DELAY),
    tap(_ => {
      services.localStorage.saveState({
        ...store$.value,
        auth: {
          ...store$.value.auth,
          data: null // Data can be a massive object, don't save it
        },
        // Don't save router state, already implicit in browser url
        router: undefined
      });
    }),
    ignoreElements()
  );

export default combineEpics(saveStateInLocalStorage);
