import { combineEpics, Epic } from "redux-observable";
import { concat, from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import * as api from "../../services/api";
import { RootAction, RootState } from "../../store";
import { SIGNED_IN } from "../auth/constants";
import * as actions from "./actions";
import { FETCH_EC2_INSTANCES } from "./constants";

export const init: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(SIGNED_IN)),
    mergeMap(_ => of(actions.fetchEc2Instances()))
  );

export const fetchEc2Instances: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_EC2_INSTANCES)),
    mergeMap(_ =>
      from(api.fetchEc2Instances()).pipe(
        mergeMap(ec2Instances =>
          concat(of(actions.fetchEc2InstancesSuccess(ec2Instances)))
        ),
        catchError(err => of(actions.fetchEc2InstancesFailure(err)))
      )
    )
  );

export default combineEpics(fetchEc2Instances, init);
