import { combineEpics, Epic } from "redux-observable";
import { concat, from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import * as api from "../../services/api";
import { RootAction, RootState } from "../../store";
import { SIGNED_IN } from "../auth/constants";
import * as actions from "./actions";
import { FETCH_ADMINS, MAKE_ADMIN, REVOKE_ADMIN } from "./constants";

export const init: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(SIGNED_IN)),
    mergeMap(_ => of(actions.fetchAdmins()))
  );

export const fetchAdmins: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_ADMINS)),
    mergeMap(_ =>
      from(api.fetchAdmins()).pipe(
        mergeMap(users => concat(of(actions.fetchAdminsSuccess(users)))),
        catchError(err => of(actions.fetchAdminsFailure(err)))
      )
    )
  );

export const makeAdmin: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(MAKE_ADMIN)),
    mergeMap(action =>
      from(api.makeAdmin(action.payload.sub)).pipe(
        mergeMap(users =>
          concat(
            of(actions.makeAdminSuccess(action.payload.sub)),
            of(actions.fetchAdmins())
          )
        ),
        catchError(err => of(actions.makeAdminFailure(action.payload.sub, err)))
      )
    )
  );

export const revokeAdmin: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(REVOKE_ADMIN)),
    mergeMap(action =>
      from(api.revokeAdmin(action.payload.sub)).pipe(
        mergeMap(users =>
          concat(
            of(actions.revokeAdminSuccess(action.payload.sub)),
            of(actions.fetchAdmins())
          )
        ),
        catchError(err =>
          of(actions.revokeAdminFailure(action.payload.sub, err))
        )
      )
    )
  );

export default combineEpics(fetchAdmins, init, makeAdmin, revokeAdmin);
