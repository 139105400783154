/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, TextField, Typography } from "@material-ui/core";

import AuthPiece from "./AuthPiece";
import { FormControl, ButtonRow } from ".";
import ProgressButton from "../core/ProgressButton";

export default class VerifyContact extends AuthPiece {
  static propTypes = {
    verifyAttr: PropTypes.string,
    changeState: PropTypes.func.isRequired,
    verifyCurrentUserAttribute: PropTypes.func.isRequired,
    verifyCurrentUserAttributeSubmit: PropTypes.func.isRequired
  };

  submit = async () => {
    const verifyAttr = this.props;
    const { code } = this.inputs;
    if (verifyAttr) {
      await this.props.verifyCurrentUserAttributeSubmit(verifyAttr, code);
    } else {
      await this.props.verifyCurrentUserAttribute("email");
    }
  };

  submitView() {
    const { changeState } = this.props;
    const { isBusy } = this.state;
    return (
      <div>
        <FormControl>
          <Typography align="left">
            We sent you an email with a confirmation code.
          </Typography>
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            label="Code"
            key="code"
            name="code"
            autoComplete="off"
            onChange={this.handleInputChange}
          />
        </FormControl>
        <ButtonRow>
          <Button variant="contained" onClick={() => changeState("signIn")}>
            Back to Sign In
          </Button>
          <ProgressButton
            variant="contained"
            color="primary"
            isPending={isBusy}
            onClick={this.handleSubmit}
          >
            Submit
          </ProgressButton>
        </ButtonRow>
      </div>
    );
  }

  verifyView() {
    const { changeState } = this.props;
    const { isBusy } = this.state;
    return (
      <div>
        <FormControl>
          <Typography align="left">
            Your email is currently unverified.
          </Typography>
        </FormControl>
        <ButtonRow>
          <Button variant="contained" onClick={() => changeState("signIn")}>
            Back to Sign In
          </Button>
          <ProgressButton
            variant="contained"
            color="primary"
            isPending={isBusy}
            onClick={this.handleSubmit}
          >
            Send Email
          </ProgressButton>
        </ButtonRow>
      </div>
    );
  }

  showComponent() {
    const { verifyAttr } = this.props;
    return <div>{verifyAttr ? this.submitView() : this.verifyView()}</div>;
  }
}
