import { combineEpics, Epic } from "redux-observable";
import { concat, from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import * as api from "../../services/api";
import { RootAction, RootState } from "../../store";
import { SIGNED_IN } from "../auth/constants";
import * as actions from "./actions";
import { FETCH_DOMAIN_USAGE, FETCH_DOMAINS } from "./constants";

export const init: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(SIGNED_IN)),
    mergeMap(_ => of(actions.fetchDomains()))
  );

export const fetchDomains: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_DOMAINS)),
    mergeMap(_ =>
      from(api.fetchDomains()).pipe(
        mergeMap(domains => concat(of(actions.fetchDomainsSuccess(domains)))),
        catchError(err => of(actions.fetchDomainsFailure(err)))
      )
    )
  );

// TODO Add test
export const fetchDomainUsage: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_DOMAIN_USAGE)),
    mergeMap(action =>
      from(api.fetchDomainUsage(action.payload)).pipe(
        mergeMap(domainUsageResponse =>
          concat(
            of(
              actions.fetchDomainUsageSuccess(
                action.payload,
                domainUsageResponse
              )
            )
          )
        ),
        catchError(err =>
          of(actions.fetchDomainUsageFailure(action.payload, err))
        )
      )
    )
  );

export default combineEpics(fetchDomainUsage, fetchDomains, init);
