export const FETCH_ADMINS = "FETCH_ADMINS";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAILURE = "FETCH_ADMINS_FAILURE";

export const MAKE_ADMIN = "MAKE_ADMIN";
export const MAKE_ADMIN_SUCCESS = "MAKE_ADMIN_SUCCESS";
export const MAKE_ADMIN_FAILURE = "MAKE_ADMIN_FAILURE";

export const REVOKE_ADMIN = "REVOKE_ADMIN";
export const REVOKE_ADMIN_SUCCESS = "REVOKE_ADMIN_SUCCESS";
export const REVOKE_ADMIN_FAILURE = "REVOKE_ADMIN_FAILURE";
