import { combineEpics, Epic } from "redux-observable";
import { concat, from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import * as api from "../../services/api";
import { RootAction, RootState } from "../../store";
import { FETCH_ADMINS_SUCCESS } from "../admins/constants";
import { SIGNED_IN } from "../auth/constants";
import * as actions from "./actions";
import { FETCH_USERS, FETCH_USERS_SUCCESS } from "./constants";

export const init: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(SIGNED_IN)),
    mergeMap(_ => of(actions.fetchUsers()))
  );

export const fetchUsers: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_USERS)),
    mergeMap(_ =>
      from(api.fetchUsers()).pipe(
        mergeMap(users => concat(of(actions.fetchUsersSuccess(users)))),
        catchError(err => of(actions.fetchUsersFailure(err)))
      )
    )
  );

// Do processing that requires both instance and ec2instance data.
export const fetchAdminsandUsersSuccess: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      action =>
        (isOfType(FETCH_USERS_SUCCESS)(action) ||
          isOfType(FETCH_ADMINS_SUCCESS)(action)) &&
        (state$.value.users.hasFetchedInitialUsers &&
          state$.value.admins.hasFetchedInitialAdmins)
    ),
    mergeMap(_ => of(actions.fetchUsersAndAdminsSuccess(state$.value.admins)))
  );

export default combineEpics(fetchUsers, init, fetchAdminsandUsersSuccess);
