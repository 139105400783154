import { Typography } from "@material-ui/core";
import React, { PureComponent } from "react";

import "./IEWelcome.css";

export default class IEWelcome extends PureComponent {
  public render() {
    return (
      <div>
        <div className="ie-welcome">
          <div className="ie-welcome-header">
            <Typography variant="title">
              Internet Explorer not supported
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}
