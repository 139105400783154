import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import {
  FETCH_EDGE_CONFIGS,
  FETCH_EDGE_CONFIGS_FAILURE,
  FETCH_EDGE_CONFIGS_SUCCESS
} from "./constants";
import { EdgeConfig } from "./models";

export type EdgeConfigsAction = ActionType<typeof actions>;

export type EdgeConfigsState = {
  edgeConfigs: EdgeConfig[];
  hasFetchedInitialEdgeConfigs: boolean;
  hasFetchedInstances: boolean;
};

export default combineReducers<EdgeConfigsState, EdgeConfigsAction>({
  edgeConfigs: (state = [], action) => {
    switch (action.type) {
      case FETCH_EDGE_CONFIGS_SUCCESS:
        const edgeConfigs: EdgeConfig[] = [];
        action.payload.forEach(ecr => {
          // If the edge config was previously fetched, then update its data, otherwise add it.
          const edgeConfig = state.find(
            ec => ec.organization === ecr.organization && ec.name === ecr.name
          );
          if (edgeConfig) {
            edgeConfigs.push({ ...edgeConfig, ...ecr });
          } else {
            edgeConfigs.push({
              ...ecr
            });
          }
        });
        return edgeConfigs;

      case FETCH_EDGE_CONFIGS_FAILURE:
        return [];

      default:
        return state;
    }
  },
  hasFetchedInitialEdgeConfigs: (state = false, action) => {
    switch (action.type) {
      case FETCH_EDGE_CONFIGS_SUCCESS:
        return true;
      default:
        return state;
    }
  },

  hasFetchedInstances: (state = false, action) => {
    switch (action.type) {
      case FETCH_EDGE_CONFIGS: {
        return false;
      }
      case FETCH_EDGE_CONFIGS_SUCCESS: {
        return true;
      }
      default: {
        return state;
      }
    }
  }
});
