import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import {
  FETCH_DEPLOYMENTS,
  FETCH_DEPLOYMENTS_FAILURE,
  FETCH_DEPLOYMENTS_SUCCESS
} from "./constants";
import { Deployment } from "./models";

export type DeploymentsAction = ActionType<typeof actions>;

export type DeploymentsState = {
  deployments: Deployment[];
  hasFetchedInitialDeployments: boolean;
  hasFetchedDeployments: boolean;
};

export default combineReducers<DeploymentsState, DeploymentsAction>({
  deployments: (state = [], action) => {
    switch (action.type) {
      case FETCH_DEPLOYMENTS_SUCCESS:
        const deployments: Deployment[] = [];
        action.payload.deploymentResponses.forEach(dr => {
          // If the deployment was previously fetched, then update its data, otherwise add it.
          const deployment = state.find(
            d => d.organization === dr.organization
          );
          if (deployment) {
            deployments.push({ ...deployment, ...dr });
          } else {
            deployments.push({
              ...dr
            });
          }
        });
        // TODO Need to remove deployments that are no longer in the list
        return deployments;

      case FETCH_DEPLOYMENTS_FAILURE:
        return [];

      default:
        return state;
    }
  },

  hasFetchedInitialDeployments: (state = false, action) => {
    switch (action.type) {
      case FETCH_DEPLOYMENTS_SUCCESS:
        return true;
      default:
        return state;
    }
  },

  hasFetchedDeployments: (state = false, action) => {
    switch (action.type) {
      case FETCH_DEPLOYMENTS:
        return false;
      case FETCH_DEPLOYMENTS_SUCCESS:
        return true;
      default:
        return state;
    }
  }
});
