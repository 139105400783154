const PROD_USERPOOL_ID = "us-east-1_paKD5DLL5";
const STAGING_USERPOOL_ID = "us-east-1_iRRxwdzGj";

const PROD_CLIENT_ID = "67qj0fl8ipepmvc5q72m4u63v";
const STAGING_CLIENT_ID = "7o12gq9c5e9al55ci225tvpmvr";

const PROD_API_URL = "https://console.tenefit.cloud/api/admin";
const STAGING_API_URL = "https://console-staging.tenefit.cloud/api/admin";

const isProd =
  window.location.hostname !== "localhost" &&
  window.location.hostname.indexOf("staging") === -1;

export const userPoolId = isProd ? PROD_USERPOOL_ID : STAGING_USERPOOL_ID;

export const clientId = isProd ? PROD_CLIENT_ID : STAGING_CLIENT_ID;

export const apiUrl = isProd ? PROD_API_URL : STAGING_API_URL;
