import { action } from "typesafe-actions";

import { AdminResponse } from "../../services/api";
import {
  FETCH_ADMINS,
  FETCH_ADMINS_FAILURE,
  FETCH_ADMINS_SUCCESS,
  MAKE_ADMIN,
  MAKE_ADMIN_FAILURE,
  MAKE_ADMIN_SUCCESS,
  REVOKE_ADMIN,
  REVOKE_ADMIN_FAILURE,
  REVOKE_ADMIN_SUCCESS
} from "./constants";

export const fetchAdmins = () => action(FETCH_ADMINS);
export const fetchAdminsSuccess = (adminResponses: AdminResponse[]) =>
  action(FETCH_ADMINS_SUCCESS, { adminResponses });
export const fetchAdminsFailure = (error: any) =>
  action(FETCH_ADMINS_FAILURE, { error });

export const makeAdmin = (sub: string) => action(MAKE_ADMIN, { sub });
export const makeAdminSuccess = (sub: string) =>
  action(MAKE_ADMIN_SUCCESS, { sub });
export const makeAdminFailure = (sub: string, error: any) =>
  action(MAKE_ADMIN_FAILURE, { sub, error });

export const revokeAdmin = (sub: string) => action(REVOKE_ADMIN, { sub });
export const revokeAdminSuccess = (sub: string) =>
  action(REVOKE_ADMIN_SUCCESS, { sub });
export const revokeAdminFailure = (sub: string, error: any) =>
  action(REVOKE_ADMIN_FAILURE, { sub, error });
