const NODE_ENV = process.env.NODE_ENV;
// Passed by Jenkins when we npm run build for release
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

const STATE_KEY = `AdminAppSerializedState.${
  NODE_ENV === "development" ? "dev" : REACT_APP_VERSION
}`;

/**
 * Loads the application state as saved in localStorage. This state can be used
 * to rehydrate the application and start where the user last left off.
 */
export function loadState<T = object>(): T | undefined {
  const { localStorage } = window;
  try {
    const serializedState = localStorage.getItem(STATE_KEY);
    if (serializedState == null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

/**
 * Stores the Redux state into localStorage so that it can be used to rehydrate
 * the app on future sessions.
 *
 * Warning: This function is expensive. Use with throttling.
 */
export function saveState<T = object>(state: T) {
  try {
    const serializedState = JSON.stringify(state);
    window.localStorage.setItem(STATE_KEY, serializedState);
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.error("Error saving state:", err);
  }
}
