import { combineEpics, Epic } from "redux-observable";
import { concat, from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import * as api from "../../services/api";
import { RootAction, RootState } from "../../store";
import { SIGNED_IN } from "../auth/constants";
import * as actions from "./actions";
import { FETCH_DEPLOYMENTS, POST_DEPLOYMENT } from "./constants";

export const init: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(SIGNED_IN)),
    mergeMap(_ => of(actions.fetchDeployments()))
  );

export const fetchDeployments: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_DEPLOYMENTS)),
    mergeMap(_ =>
      from(api.fetchDeployments()).pipe(
        mergeMap(instances =>
          concat(of(actions.fetchDeploymentsSuccess(instances)))
        ),
        catchError(err => of(actions.fetchDeploymentsFailure(err)))
      )
    )
  );

// TODO Add a test
export const postDeployment: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(POST_DEPLOYMENT)),
    mergeMap(action =>
      from(
        api.postDeployment(
          action.payload.organization,
          action.payload.instanceId
        )
      ).pipe(
        mergeMap(result =>
          concat(
            of(actions.postDeploymentSuccess(result)),
            of(actions.fetchDeployments())
          )
        ),
        catchError(err =>
          of(
            actions.postDeploymentFailure(
              action.payload.organization,
              action.payload.instanceId,
              err
            )
          )
        )
      )
    )
  );

export default combineEpics(fetchDeployments, init, postDeployment);
