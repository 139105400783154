import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import Instances, { InstancesProps } from "../components/Instances";
import { deploymentsActions } from "../features/deployments";
import { instancesActions } from "../features/instances";
import { organizationsActions } from "../features/organizations";
import { RootState } from "../store";

const InstancesContainer = (props: InstancesProps) => {
  return <Instances {...props} />;
};

const mapStateToProps = (state: RootState) => {
  return {
    instancesState: state.instances,
    organizationsState: state.organizations,
    domains: state.domains.domains,
    hasFetchedInitialDomains: state.domains.hasFetchedInitialDomains,
    usersState: state.users,
    hasFetchedInitialAdmins: state.admins.hasFetchedInitialAdmins
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  fetchOrganizationUsage: (organizationName: string, numDomains: number) => {
    dispatch(
      organizationsActions.fetchOrganizationUsage(organizationName, numDomains)
    );
  },
  handleMakeProduction: (organization: string, instanceId: string) =>
    dispatch(deploymentsActions.postDeployment(organization, instanceId)),
  handleLaunchNewInstance: (organization: string) =>
    dispatch(organizationsActions.launchNewInstance(organization)),
  handleInstanceDelete: (instanceId: string) =>
    dispatch(instancesActions.deleteInstance(instanceId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstancesContainer);
