import { CircularProgress, Theme, Tooltip } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { mdiAccountStar } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import moment from "moment";
import React, { PureComponent } from "react";

import { User } from "../features/users/models";
import ProgressButton from "./core/ProgressButton";
import { borderColor, TableCellCentered, TableCellCommon } from "./Instances";

import "./Instances.css";

export type UsersRowProps = {
  user: User;
  isRevokingAdmin: boolean;
  makeAdmin: ((sub: string) => void) | null;
  revokeAdmin: (sub: string) => void;
};

export type UsersRowState = {};

// TODO Make into a constant used everywhere
export const circularProgressSize = 12;

const dateFormat = "MM-DD-YYYY HH:mm:ss a";

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    tableSeparatorRow: {
      // height: 48
    },
    tableRow: {
      // height: 164
    },
    tableCell: {
      ...TableCellCommon,
      borderTop: `solid 1px ${borderColor}`,
      borderBottom: "none",
      fontSize: "0.75rem",
      whiteSpace: "nowrap",
    },
    tableCellNoBorder: { borderTop: "none" },
    tableButton: {
      // TODO Change name and make generic for all screens
      fontSize: "0.9em",
      minHeight: 24,
      minWidth: 85,
    },
    revokeButton: {
      // TODO Make this generic as there are other red buttons in the app
      color: palette.getContrastText(red[700]),
      backgroundColor: red[700],
      "&:hover": {
        backgroundColor: red[900],
      },
    },
    centered: { ...TableCellCentered },
    orphanCell: {
      color: red[700],
    },
    noOrgError: {
      backgroundColor: red[700],
      color: "#ffffff",
    },
  });

class UsersRow extends PureComponent<
  UsersRowProps & WithStyles<typeof styles>,
  UsersRowState
> {
  public state: UsersRowState = {};

  public render() {
    const { user, isRevokingAdmin, classes } = this.props;
    const {} = this.state;

    const isOrphan = user.username === "";

    const createdDate = moment(user.createdDate);
    const lastSignedIn = moment(user.lastSignedIn);

    const userHasNoOrg = user.organizations === null;

    return (
      <TableRow
        classes={{
          root: classNames(classes.tableRow),
        }}
      >
        <TableCell
          classes={{
            root: classNames(
              classes.tableCell,
              isOrphan ? classes.orphanCell : "",
              { [classes.noOrgError]: userHasNoOrg }
            ),
          }}
        >
          {user.sub}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.tableCell,
              classes.centered,
              isOrphan ? classes.orphanCell : "",
              { [classes.noOrgError]: userHasNoOrg }
            ),
          }}
        >
          {user.hasFetchedAdmin && user.isAdmin ? (
            <Tooltip
              placement="top"
              title="Admin user"
              disableFocusListener={true}
            >
              <Icon
                path={mdiAccountStar}
                size="22px"
                color={`rgba(${isOrphan ? 255 : 0}, 0, 0, 0.54)`}
              />
            </Tooltip>
          ) : (
            ""
          )}
          {!user.hasFetchedAdmin && <CircularProgress size={12} />}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(classes.tableCell, classes.centered, {
              [classes.noOrgError]: userHasNoOrg,
            }),
          }}
        >
          {user.hasFetchedAdmin &&
            (user.isAdmin ? (
              <ProgressButton
                variant="contained"
                color="primary"
                size="small"
                classes={{
                  root: classNames(classes.tableButton, classes.revokeButton),
                }}
                disabled={false}
                isPending={isRevokingAdmin}
                didSucceed={false}
                onClick={this.revokeAdmin}
              >
                Revoke
              </ProgressButton>
            ) : (
              <ProgressButton
                variant="contained"
                color="primary"
                size="small"
                classes={{
                  root: classes.tableButton,
                }}
                disabled={false}
                isPending={user.isMakingAdmin}
                didSucceed={false}
                onClick={this.makeAdmin}
              >
                Make admin
              </ProgressButton>
            ))}
          {!user.hasFetchedAdmin && <CircularProgress size={12} />}
        </TableCell>

        {!isOrphan && (
          <React.Fragment>
            <TableCell
              classes={{
                root: classNames(classes.tableCell, {
                  [classes.noOrgError]: userHasNoOrg,
                }),
              }}
            >
              {user.username}
            </TableCell>
            <TableCell
              classes={{
                root: classNames(classes.tableCell, {
                  [classes.noOrgError]: userHasNoOrg,
                }),
              }}
            >
              {user.email}
            </TableCell>
            <TableCell
              classes={{
                root: classNames(classes.tableCell, {
                  [classes.noOrgError]: userHasNoOrg,
                }),
              }}
            >
              {user.organizations
                ? user.organizations.join(", ")
                : "Error: This user has no org. That's bad!"}
            </TableCell>
            <TableCell
              classes={{
                root: classNames(classes.tableCell, {
                  [classes.noOrgError]: userHasNoOrg,
                }),
              }}
            >
              {user.createdDate ? (
                <span>
                  {createdDate.fromNow()}{" "}
                  <span className="actualDate">
                    ({createdDate.format(dateFormat)})
                  </span>
                </span>
              ) : (
                ""
              )}
            </TableCell>
            <TableCell
              classes={{
                root: classNames(classes.tableCell, {
                  [classes.noOrgError]: userHasNoOrg,
                }),
              }}
            >
              {user.lastSignedIn ? (
                <span>
                  {lastSignedIn.fromNow()}{" "}
                  <span className="actualDate">
                    ({lastSignedIn.format(dateFormat)})
                  </span>
                </span>
              ) : (
                ""
              )}
            </TableCell>
          </React.Fragment>
        )}
        {isOrphan && (
          <TableCell
            classes={{
              root: classNames(
                classes.tableCell,
                isOrphan ? classes.orphanCell : ""
              ),
            }}
            colSpan={5}
          >
            No user corresponds with this subject, so admin can be safely
            revoked
          </TableCell>
        )}
      </TableRow>
    );
  }

  private makeAdmin = () => {
    if (this.props.makeAdmin) {
      this.props.makeAdmin(this.props.user.sub);
    }
  };

  private revokeAdmin = () => {
    this.props.revokeAdmin(this.props.user.sub);
  };
}

export default withStyles(styles)(UsersRow);
