import { action } from "typesafe-actions";

import { Ec2InstanceResponse } from "../../services/api";
import {
  FETCH_EC2_INSTANCES,
  FETCH_EC2_INSTANCES_FAILURE,
  FETCH_EC2_INSTANCES_SUCCESS
} from "./constants";

export const fetchEc2Instances = () => action(FETCH_EC2_INSTANCES);
export const fetchEc2InstancesSuccess = (
  ec2InstanceResponses: Ec2InstanceResponse[]
) => action(FETCH_EC2_INSTANCES_SUCCESS, ec2InstanceResponses);
export const fetchEc2InstancesFailure = (error: any) =>
  action(FETCH_EC2_INSTANCES_FAILURE, { error });
