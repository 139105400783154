import { action } from "typesafe-actions";

import { DeploymentResponse } from "../../services/api";
import {
  FETCH_DEPLOYMENTS,
  FETCH_DEPLOYMENTS_FAILURE,
  FETCH_DEPLOYMENTS_SUCCESS,
  POST_DEPLOYMENT,
  POST_DEPLOYMENT_FAILURE,
  POST_DEPLOYMENT_SUCCESS
} from "./constants";

export const fetchDeployments = () => action(FETCH_DEPLOYMENTS);
export const fetchDeploymentsSuccess = (
  deploymentResponses: DeploymentResponse[]
) => action(FETCH_DEPLOYMENTS_SUCCESS, { deploymentResponses });
export const fetchDeploymentsFailure = (error: any) =>
  action(FETCH_DEPLOYMENTS_FAILURE, { error });

export const postDeployment = (organization: string, instanceId: string) =>
  action(POST_DEPLOYMENT, { organization, instanceId });
export const postDeploymentSuccess = (deploymentResponse: DeploymentResponse) =>
  action(POST_DEPLOYMENT_SUCCESS, { deploymentResponse });
export const postDeploymentFailure = (
  organization: string,
  instanceId: string,
  error: any
) => action(POST_DEPLOYMENT_FAILURE, { organization, instanceId, error });
