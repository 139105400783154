import { action } from "typesafe-actions";

import {
  AUTH_ERROR,
  CHANGE_STATE,
  SET_DELIVERY,
  SET_VERIFY_ATTRIBUTE,
  SIGNED_IN,
  SIGNED_OUT
} from "./constants";
import { AuthState } from "./models";
import { AdminUser } from "./reducer";

export const changeState = (state: AuthState, data: any = null) =>
  action(CHANGE_STATE, { data, state });

export const error = (error: Error) => action(AUTH_ERROR, error);

export const setDelivery = (delivery: object | null) =>
  action(SET_DELIVERY, delivery);

export const setVerifyAttr = (attribute: string | null) =>
  action(SET_VERIFY_ATTRIBUTE, attribute);

export const signedIn = (user: AdminUser) => action(SIGNED_IN, user);

export const signedOut = () => action(SIGNED_OUT);
