import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import {
  DELETE_INSTANCE,
  DELETE_INSTANCE_FAILURE,
  FETCH_INSTANCES,
  FETCH_INSTANCES_AND_DEPLOYMENTS_SUCCESS,
  FETCH_INSTANCES_AND_EC2_INSTANCES_SUCCESS,
  FETCH_INSTANCES_FAILURE,
  FETCH_INSTANCES_SUCCESS
} from "./constants";
import { Instance } from "./models";

export type InstancesAction = ActionType<typeof actions>;

export type InstancesState = {
  byInstanceId: {
    [name: string]: Instance;
  };
  hasFetchedInitialInstances: boolean;
  hasFetchedInstances: boolean;
};

export default combineReducers<InstancesState, InstancesAction>({
  byInstanceId: (state = {}, action) => {
    switch (action.type) {
      case FETCH_INSTANCES_SUCCESS: {
        const instancesResponse = action.payload.instancesResponse;
        // Add new instances, or update instances, based on the new list just fetched.
        const newState = instancesResponse.reduce((state, instanceResponse) => {
          let newInstance: Instance;
          const instanceId = instanceResponse.instanceId;
          if (instanceId in state) {
            newInstance = {
              ...state[instanceId],
              ...instanceResponse
            };
          } else {
            newInstance = {
              ...instanceResponse,
              hasFetchedEc2Instance: false,
              ec2InstanceState: null,
              publicIpAddress: null,
              hasFetchedDeployment: false,
              isProduction: false,
              isPendingDelete: false
            };
          }
          return {
            ...state,
            [instanceId]: newInstance
          };
        }, state);
        // Check if we have existing instances in our list that aren't in the newly fetched list.
        // Any found, should be removed.
        return Object.keys(newState).reduce((state, instanceId) => {
          const instanceResponse = instancesResponse.findIndex(ir => {
            return ir.instanceId === instanceId;
          });
          if (instanceResponse !== -1) {
            return state;
          }
          const newState = { ...state };
          delete newState[instanceId];
          return newState;
        }, newState);
      }

      case FETCH_INSTANCES_FAILURE: {
        return {};
      }

      case DELETE_INSTANCE: {
        let isPendingDelete = false;
        if (state[action.payload]) {
          isPendingDelete = true;
        }
        return {
          ...state,
          [action.payload]: { ...state[action.payload], isPendingDelete }
        };
      }

      case DELETE_INSTANCE_FAILURE: {
        return {
          ...state,
          [action.payload.instanceId]: {
            ...state[action.payload.instanceId],
            isPendingDelete: false
          }
        };
      }

      case FETCH_INSTANCES_AND_EC2_INSTANCES_SUCCESS: {
        const ec2InstancesState = action.payload.ec2InstancesState;
        return Object.keys(state).reduce((state, instanceId) => {
          const newInstance = { ...state[instanceId] };
          const ec2Instance = ec2InstancesState.byInstanceId[instanceId];
          if (ec2Instance) {
            newInstance.ec2InstanceState = ec2Instance.state.name;
            newInstance.publicIpAddress = ec2Instance.publicIpAddress;
          } else {
            newInstance.ec2InstanceState = null;
            newInstance.publicIpAddress = null;
          }
          newInstance.hasFetchedEc2Instance = true;
          return {
            ...state,
            [instanceId]: newInstance
          };
        }, state);
      }

      case FETCH_INSTANCES_AND_DEPLOYMENTS_SUCCESS: {
        const deploymentsState = action.payload.deploymentsState;
        return Object.keys(state).reduce((state, instanceId) => {
          const newInstance = { ...state[instanceId] };
          const deployment = deploymentsState.deployments.find(
            d =>
              newInstance.organization === d.organization &&
              newInstance.instanceId === d.instanceId
          );
          if (deployment) {
            newInstance.isProduction = true;
          } else {
            newInstance.isProduction = false;
          }
          newInstance.hasFetchedDeployment = true;
          return {
            ...state,
            [instanceId]: newInstance
          };
        }, state);
      }

      default: {
        return state;
      }
    }
  },

  hasFetchedInitialInstances: (state = false, action) => {
    switch (action.type) {
      case FETCH_INSTANCES_SUCCESS: {
        return true;
      }
      default: {
        return state;
      }
    }
  },

  hasFetchedInstances: (state = false, action) => {
    switch (action.type) {
      case FETCH_INSTANCES: {
        return false;
      }
      case FETCH_INSTANCES_SUCCESS: {
        return true;
      }
      default: {
        return state;
      }
    }
  }
});
