import { Paper, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { CSSProperties, WithStyles } from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import React, { PureComponent } from "react";
import { OptionsType } from "react-select/lib/types";

import { InstancesState } from "../features/instances";
import { OrganizationsState } from "../features/organizations";
import { Organization } from "../features/organizations/models";
import AutoComplete from "./AutoComplete";
import { AutoCompleteSelection } from "./AutoCompleteModels";
import ProgressButton from "./core/ProgressButton";

import "./Instances.css";

export type InstancesActionsProps = {
  instancesState: InstancesState;
  organizationsState: OrganizationsState;
  hasFetchedInitialDomains: boolean;
  handleMakeProduction: (organization: string, instanceId: string) => void;
  handleLaunchNewInstance: (organization: string) => void;
  handleInstanceDelete: (instanceId: string) => void;
  handleFetchUsageForAllDomains: () => void;
};

export type InstancesActionsState = {
  newInstanceOrg: string | null;
};

export const InstancesTableCellCommon: CSSProperties = {
  textAlign: "left",
  paddingLeft: 8,
  paddingRight: 8
};

export const InstancesTableCellCentered: CSSProperties = {
  textAlign: "center"
};

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    pageActionsPaper: {
      padding: 20,
      display: "flex",
      alignItems: "center",
      marginLeft: 20,
      "&:first-of-type": {
        marginLeft: 0
      }
    },
    pageActionsPaperLaunch: {
      // flexGrow: 1
      width: 500
    },
    pageActionsButton: {
      fontSize: "0.9em",
      minHeight: 24
    },
    launchNewInstanceButton: {
      marginLeft: 20
    }
  });

/**
 * Compare function for sorting organizations.
 */
export function compareOrganizations(a: Organization, b: Organization) {
  return a.name.localeCompare(b.name);
}

class InstanceActions extends PureComponent<
  InstancesActionsProps & WithStyles<typeof styles>,
  InstancesActionsState
> {
  public state: InstancesActionsState = { newInstanceOrg: null };

  public render() {
    const {
      instancesState,
      organizationsState,
      hasFetchedInitialDomains,
      classes,
      handleFetchUsageForAllDomains
    } = this.props;
    const hasFetchedInitialInstances =
      instancesState.hasFetchedInitialInstances;
    const organizations = organizationsState.byName;
    const hasFetchedInitialOrganizations =
      organizationsState.hasFetchedInitialOrganizations;
    const { newInstanceOrg } = this.state;

    const suggestions: OptionsType<any> = [];
    const organizationsSorted: Organization[] = [];
    Object.keys(organizations).forEach(orgName => {
      const organization = organizations[orgName];
      organizationsSorted.push(organization);
    });
    organizationsSorted.sort(compareOrganizations).forEach(o => {
      suggestions.push({ label: o.name, value: o.name });
    });

    return (
      <div className="Instances-actions">
        <Paper
          className={classNames(
            classes.pageActionsPaper,
            classes.pageActionsPaperLaunch
          )}
        >
          <AutoComplete
            suggestions={suggestions}
            placeholder="Select an org..."
            isDisabled={!hasFetchedInitialOrganizations}
            handleChange={this.handleLaunchNewInstanceOrgSelection}
            doClearSelection={newInstanceOrg === null}
          />
          <ProgressButton
            variant="contained"
            color="primary"
            size="small"
            classes={{
              root: classNames(
                classes.pageActionsButton,
                classes.launchNewInstanceButton
              )
            }}
            disabled={!hasFetchedInitialOrganizations || !newInstanceOrg}
            isPending={false}
            didSucceed={false}
            onClick={this.handleLaunchNewInstance}
          >
            Upgrade or launch new instance
          </ProgressButton>
        </Paper>
        <Paper className={classes.pageActionsPaper}>
          <Typography>All running organizations:</Typography>
          <ProgressButton
            variant="contained"
            color="primary"
            size="small"
            classes={{
              root: classNames(
                classes.pageActionsButton,
                classes.launchNewInstanceButton
              )
            }}
            disabled={
              !hasFetchedInitialInstances || !hasFetchedInitialOrganizations
            }
            isPending={false}
            didSucceed={false}
            onClick={this.handleLaunchNewInstanceForAllOrgs}
          >
            Upgrade
          </ProgressButton>
          <ProgressButton
            variant="contained"
            color="primary"
            size="small"
            classes={{
              root: classNames(
                classes.pageActionsButton,
                classes.launchNewInstanceButton
              )
            }}
            disabled={
              !hasFetchedInitialInstances ||
              !hasFetchedInitialOrganizations ||
              !hasFetchedInitialDomains
            }
            isPending={false}
            didSucceed={false}
            onClick={handleFetchUsageForAllDomains}
          >
            Get usage
          </ProgressButton>
        </Paper>
      </div>
    );
  }

  private handleLaunchNewInstanceOrgSelection = (
    selectedOrg: AutoCompleteSelection
  ) => {
    this.setState({
      newInstanceOrg: selectedOrg ? selectedOrg.value : null
    });
  };

  private handleLaunchNewInstanceForAllOrgs = () => {
    // Launch for any organizations that have at least one running instance.
    const organizations = this.props.organizationsState.byName;
    const instances = this.props.instancesState.byInstanceId;
    Object.keys(organizations).forEach(orgName => {
      const index = Object.keys(instances).findIndex(
        i =>
          instances[i].organization === orgName &&
          instances[i].instanceState === "RUNNING"
      );
      if (index >= 0) {
        this.props.handleLaunchNewInstance(orgName);
      }
    });
  };

  private handleLaunchNewInstance = () => {
    this.props.handleLaunchNewInstance(this.state.newInstanceOrg!);
    this.setState({
      newInstanceOrg: null
    });
  };
}

export default withStyles(styles)(InstanceActions);
