import services from ".";
import * as api from "./api";
import * as localStorage from "./localStorage";

export default {
  api,
  localStorage
};

export type Services = typeof services;
