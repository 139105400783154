export const FETCH_INSTANCES = "FETCH_INSTANCES";
export const FETCH_INSTANCES_SUCCESS = "FETCH_INSTANCES_SUCCESS";
export const FETCH_INSTANCES_FAILURE = "FETCH_INSTANCES_FAILURE";

export const DELETE_INSTANCE = "DELETE_INSTANCE";
export const DELETE_INSTANCE_SUCCESS = "DELETE_INSTANCE_SUCCESS";
export const DELETE_INSTANCE_FAILURE = "DELETE_INSTANCE_FAILURE";

export const FETCH_INSTANCES_AND_EC2_INSTANCES_SUCCESS =
  "FETCH_INSTANCES_AND_EC2_INSTANCES_SUCCESS";
export const FETCH_INSTANCES_AND_DEPLOYMENTS_SUCCESS =
  "FETCH_INSTANCES_AND_DEPLOYMENTS_SUCCESS";
