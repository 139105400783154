import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  ButtonBase,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import "./Header.css";
import tenefitInsignia from "../assets/tenefit-icon.png";
import UserChip from "../components/UserChip";

const styles = (theme) => ({
  header: {
    backgroundColor: "#ea884b",
  },
  brand: {
    color: "#ffffff",
    fontSize: 20,
    opacity: 0.8,
  },
  brandLogo: {
    opacity: 0.75,
  },
});

class Header extends Component {
  static propTypes = {
    user: PropTypes.shape({}),
    signOut: PropTypes.func,
  };

  menuAnchorRef = React.createRef();
  state = { userMenuAnchorEl: null };

  showMenu = (target) => this.setState({ userMenuAnchorEl: target });
  hideMenu = () => this.setState({ userMenuAnchorEl: null });

  redirectTo = (path) => <Redirect to={path} />;

  handleSignOut = () => {
    this.hideMenu();
    this.props.signOut();
  };

  render() {
    const { user, classes } = this.props;
    const { userMenuAnchorEl } = this.state;
    return (
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <ButtonBase
            color="inherit"
            aria-label="Home"
            component={Link}
            to="/"
            className="Header-homeButton"
          >
            <img
              className={classes.brandLogo}
              src={tenefitInsignia}
              alt="logo"
            />
            <Typography className={classes.brand}>Admin App</Typography>
          </ButtonBase>
          {user && (
            <React.Fragment>
              <div className="Header-menuItems">
                <Link to="/instances">Instances</Link>
                <Link to="/domains">Domains</Link>
                <Link to="/users">Users</Link>
                <Link to="/configs">Configs</Link>
              </div>
              <div className="Header-spacing" />
              <UserChip
                name={user.username}
                onClick={(event) => this.showMenu(event.target)}
              />
            </React.Fragment>
          )}
          <div ref={this.menuAnchorRef} className="Header-menuAnchor" />
          <Menu
            open={!!userMenuAnchorEl}
            anchorEl={this.menuAnchorRef.current}
            onClose={this.hideMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem key="signOut" onClick={this.handleSignOut}>
              Sign Out
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Header);
