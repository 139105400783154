import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import { AdminsAction } from "../admins";
import { MAKE_ADMIN, MAKE_ADMIN_FAILURE } from "../admins/constants";
import * as actions from "./actions";
import {
  FETCH_USERS_AND_ADMINS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS
} from "./constants";
import { User } from "./models";

export type UsersAction = ActionType<typeof actions>;

export type UsersState = {
  byUsername: {
    [username: string]: User;
  };
  hasFetchedInitialUsers: boolean;
};

export default combineReducers<UsersState, UsersAction | AdminsAction>({
  byUsername: (state = {}, action) => {
    switch (action.type) {
      case FETCH_USERS_SUCCESS:
        const userResponses = action.payload.userResponses;
        // TODO Bug: Need to remove stale items if they aren't included in the fetch.
        const newState = userResponses.reduce((state, userResponse) => {
          let user: User;
          if (userResponse.username in state) {
            user = {
              ...state[userResponse.username],
              ...userResponse
            };
          } else {
            user = {
              ...userResponse,
              hasFetchedAdmin: false,
              isAdmin: false,
              isMakingAdmin: false
            };
          }

          return {
            ...state,
            [user.username]: user
          };
        }, state);

        // Check if we have existing instances in our list that aren't in the newly fetched list.
        // Any found, should be removed.
        return Object.keys(newState).reduce((state, username) => {
          const userResponse = userResponses.findIndex(ur => {
            return ur.username === username;
          });
          if (userResponse !== -1) {
            return state;
          }
          const newState = { ...state };
          delete newState[username];
          return newState;
        }, newState);

      case FETCH_USERS_FAILURE:
        return {};

      case FETCH_USERS_AND_ADMINS_SUCCESS: {
        const admins = action.payload.adminsState.bySub;
        return Object.keys(state).reduce((state, username) => {
          const newUser = { ...state[username] };
          const admin = admins[newUser.sub];
          if (admin) {
            newUser.isAdmin = true;
          } else {
            newUser.isAdmin = false;
          }
          newUser.hasFetchedAdmin = true;
          newUser.isMakingAdmin = false;
          return {
            ...state,
            [username]: newUser
          };
        }, state);
      }

      case MAKE_ADMIN: {
        const sub = action.payload.sub;
        const username = Object.keys(state).find(
          username => state[username].sub === sub
        );
        if (!username) {
          return state;
        }
        return {
          ...state,
          [username]: {
            ...state[username],
            isMakingAdmin: true
          }
        };
      }

      case MAKE_ADMIN_FAILURE: {
        const sub = action.payload.sub;
        const username = Object.keys(state).find(
          username => state[username].sub === sub
        );
        if (!username) {
          return state;
        }
        return {
          ...state,
          [username]: {
            ...state[username],
            isMakingAdmin: false
          }
        };
      }

      default:
        return state;
    }
  },

  hasFetchedInitialUsers: (state = false, action) => {
    switch (action.type) {
      case FETCH_USERS_SUCCESS:
        return true;
      default:
        return state;
    }
  }
});
