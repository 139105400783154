import { combineEpics, Epic } from "redux-observable";
import { concat, from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import * as api from "../../services/api";
import { RootAction, RootState } from "../../store";
import { SIGNED_IN } from "../auth/constants";
import * as actions from "./actions";
import { FETCH_EDGE_CONFIGS } from "./constants";

export const init: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(SIGNED_IN)),
    mergeMap(_ => of(actions.fetchEdgeConfigs()))
  );

export const fetchEdgeConfigs: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_EDGE_CONFIGS)),
    mergeMap(_ =>
      from(api.fetchEdgeConfigs()).pipe(
        mergeMap(edgeConfigs =>
          concat(of(actions.fetchEdgeConfigsSuccess(edgeConfigs)))
        ),
        catchError(err => of(actions.fetchEdgeConfigsFailure(err)))
      )
    )
  );

export default combineEpics(fetchEdgeConfigs, init);
