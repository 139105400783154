import { action } from "typesafe-actions";

import { UserResponse } from "../../services/api";
import { AdminsState } from "../admins";
import {
  FETCH_USERS,
  FETCH_USERS_AND_ADMINS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS
} from "./constants";

export const fetchUsers = () => action(FETCH_USERS);
export const fetchUsersSuccess = (userResponses: UserResponse[]) =>
  action(FETCH_USERS_SUCCESS, { userResponses });
export const fetchUsersFailure = (error: any) =>
  action(FETCH_USERS_FAILURE, { error });

export const fetchUsersAndAdminsSuccess = (adminsState: AdminsState) =>
  action(FETCH_USERS_AND_ADMINS_SUCCESS, {
    adminsState
  });
