/*
 * The central Redux store definition.
 *
 * Redux uses just one store for everything. This configures a store that
 * includes the reducers defined in the reducers/ directory, and is plugged in
 * to react router and the browser history to make react-router and redux work
 * together.
 *
 * This file also configures the Redux devtools extension, if it is installed
 * and we are in development mode.
 */

import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory as createHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import thunk from "redux-thunk";

import services, { Services } from "../services";
import rootEpic from "./rootEpic";
import rootReducer from "./rootReducer";
import { RootAction, RootState } from "./types";

export const history = createHistory();

export default function (initialState?: object) {
  const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    RootState,
    Services
  >({
    dependencies: services,
  });
  const middleware = [thunk, routerMiddleware(history), epicMiddleware];
  const enhancers = [];
  // Enable Redux dev tools if we are in development
  if (process.env.NODE_ENV === "development") {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  const store = createStore(
    connectRouter(history)(rootReducer),
    initialState!,
    composedEnhancers
  );

  epicMiddleware.run(rootEpic);

  return store;
}
