import { action } from "typesafe-actions";

import { InstanceResponse } from "../../services/api";
import { DeploymentsState } from "../deployments";
import { Ec2InstancesState } from "../ec2Instances";
import {
  DELETE_INSTANCE,
  DELETE_INSTANCE_FAILURE,
  DELETE_INSTANCE_SUCCESS,
  FETCH_INSTANCES,
  FETCH_INSTANCES_AND_DEPLOYMENTS_SUCCESS,
  FETCH_INSTANCES_AND_EC2_INSTANCES_SUCCESS,
  FETCH_INSTANCES_FAILURE,
  FETCH_INSTANCES_SUCCESS
} from "./constants";

export const fetchInstances = () => action(FETCH_INSTANCES);
export const fetchInstancesSuccess = (instancesResponse: InstanceResponse[]) =>
  action(FETCH_INSTANCES_SUCCESS, {
    instancesResponse
  });
export const fetchInstancesFailure = (error: any) =>
  action(FETCH_INSTANCES_FAILURE, { error });

export const deleteInstance = (instanceId: string) =>
  action(DELETE_INSTANCE, instanceId);
export const deleteInstanceSuccess = (instanceId: string) =>
  action(DELETE_INSTANCE_SUCCESS, instanceId);
export const deleteInstanceFailure = (instanceId: string, error: any) =>
  action(DELETE_INSTANCE_FAILURE, { instanceId, error });

export const fetchInstancesAndEc2InstancesSuccess = (
  ec2InstancesState: Ec2InstancesState
) =>
  action(FETCH_INSTANCES_AND_EC2_INSTANCES_SUCCESS, {
    ec2InstancesState
  });

export const fetchInstancesAndDeploymentsSuccess = (
  deploymentsState: DeploymentsState
) =>
  action(FETCH_INSTANCES_AND_DEPLOYMENTS_SUCCESS, {
    deploymentsState
  });
