import { connect } from "react-redux";

import Authenticator from "../components/auth/Authenticator";
import { authThunks } from "../features/auth";
import { RootState } from "../store";

const mapStateToProps = (state: RootState) => ({
  authState: state.auth.state,
  data: state.auth.data,
  delivery: state.auth.delivery,
  error: state.auth.error,
  verifyAttribute: state.auth.verifyAttribute
});

const mapDispatchToProps = {
  changeState: authThunks.changeState,
  completeNewPassword: authThunks.completeNewPassword,
  confirmSignUp: authThunks.confirmSignUp,
  currentAuthenticatedUser: authThunks.currentAuthenticatedUser,
  forgotPassword: authThunks.forgotPassword,
  forgotPasswordSubmit: authThunks.forgotPasswordSubmit,
  resendSignUp: authThunks.resendSignUp,
  signIn: authThunks.signIn,
  signUp: authThunks.signUp,
  verifiedContact: authThunks.verifiedContact,
  verifyCurrentUserAttribute: authThunks.verifyCurrentUserAttribute,
  verifyCurrentUserAttributeSubmit: authThunks.verifyCurrentUserAttributeSubmit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authenticator);
