import { combineEpics, Epic } from "redux-observable";
import { concat, forkJoin, from, of } from "rxjs";
import {
  auditTime,
  catchError,
  concatAll,
  filter,
  map,
  mergeMap
} from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import { InstancesState } from ".";
import * as api from "../../services/api";
import { RootAction, RootState } from "../../store";
import { SIGNED_IN } from "../auth/constants";
import { deploymentsActions } from "../deployments";
import { FETCH_DEPLOYMENTS_SUCCESS } from "../deployments/constants";
import * as ec2Actions from "../ec2Instances/actions";
import { FETCH_EC2_INSTANCES_SUCCESS } from "../ec2Instances/constants";
import { organizationsActions, OrganizationsState } from "../organizations";
import { Organization } from "../organizations/models";
import * as actions from "./actions";
import {
  DELETE_INSTANCE,
  FETCH_INSTANCES,
  FETCH_INSTANCES_SUCCESS
} from "./constants";

export const init: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(SIGNED_IN)),
    mergeMap(_ => of(actions.fetchInstances()))
  );

export const fetchInstances: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_INSTANCES)),
    mergeMap(_ =>
      from(api.fetchInstances()).pipe(
        mergeMap(instances => of(actions.fetchInstancesSuccess(instances))),
        catchError(err => of(actions.fetchInstancesFailure(err)))
      )
    )
  );

// Do processing that requires both instance and ec2instance data.
export const fetchInstancesAndEc2InstancesSuccess: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      action =>
        (isOfType(FETCH_INSTANCES_SUCCESS)(action) ||
          isOfType(FETCH_EC2_INSTANCES_SUCCESS)(action)) &&
        (state$.value.instances.hasFetchedInstances &&
          state$.value.ec2Instances.hasFetchedEc2Instances)
    ),
    mergeMap(_ =>
      of(
        actions.fetchInstancesAndEc2InstancesSuccess(state$.value.ec2Instances)
      )
    )
  );

// Do processing that requires both instance and deployment data.
// TODO Add a test
export const fetchInstancesAndDeploymentsSuccess: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      action =>
        (isOfType(FETCH_INSTANCES_SUCCESS)(action) ||
          isOfType(FETCH_DEPLOYMENTS_SUCCESS)(action)) &&
        (state$.value.instances.hasFetchedInstances &&
          state$.value.deployments.hasFetchedDeployments)
    ),
    mergeMap(_ =>
      of(actions.fetchInstancesAndDeploymentsSuccess(state$.value.deployments))
    )
  );

// TODO Add a test (may already exist)
export const deleteInstance: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(DELETE_INSTANCE)),
    mergeMap(action =>
      from(api.deleteInstance(action.payload)).pipe(
        mergeMap(_ =>
          concat(
            of(actions.deleteInstanceSuccess(action.payload)),
            forkJoin(
              of(actions.fetchInstances()),
              of(deploymentsActions.fetchDeployments()),
              of(organizationsActions.fetchOrganizations())
            ).pipe(concatAll())
          )
        ),
        catchError(err =>
          of(actions.deleteInstanceFailure(action.payload, err))
        )
      )
    )
  );

const getOrganizationForInstance = (
  instanceId: string,
  instancesState: InstancesState,
  organizationState: OrganizationsState
): Organization | null => {
  const instance = instancesState.byInstanceId[instanceId];
  if (instance) {
    const organization = organizationState.byName[instance!.organization!];
    if (organization) {
      return organization!;
    }
  }
  return null;
};

// Continually poll the API for the instances until they are all in a running state.
// Orphaned instances (those not in an organization) don't count.
// When polling the instances, also fetch the ec2 instances, because when you launch
// a new instance it implicitly also creates and starts an ec2 instance. So you want
// get the updated states of those, too.
// An ec2 instance should be in a running state before an instanc is. Because an instance
// needs to the ec2 instance to start plus do some additional things (on the server side, that is).
// If the user upgrades all organizations, this could result in polling occurring multiple times.
// Therefor this function will rate limit those to prevent polling the same thing multiple times,
// since each poll returns all instances anyway.
//
export const checkForNonrunningInstances: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(FETCH_INSTANCES_SUCCESS)),
    mergeMap(action =>
      from(action.payload.instancesResponse).pipe(
        mergeMap(instanceResponse =>
          of(
            getOrganizationForInstance(
              instanceResponse.instanceId,
              state$.value.instances,
              state$.value.organizations
            )
          ).pipe(
            filter(
              organization =>
                organization !== null &&
                instanceResponse.instanceState !== "RUNNING"
            )
          )
        )
      )
    ),
    auditTime(1000), // TODO Make this a variable and set an appropriate value
    mergeMap(_ =>
      concat(of(ec2Actions.fetchEc2Instances()), of(actions.fetchInstances()))
    )
  );

// Whenever instances have been fetched, check if there are any that we were waiting
// on to be made production, and if so, make them production.
// TODO Add test
export const makeInstancesProduction: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(FETCH_INSTANCES_SUCCESS)),
    mergeMap(action =>
      from(action.payload.instancesResponse).pipe(
        mergeMap(instanceResponse =>
          of(
            getOrganizationForInstance(
              instanceResponse.instanceId,
              state$.value.instances,
              state$.value.organizations
            )
          ).pipe(
            filter(
              organization =>
                organization !== null &&
                instanceResponse.instanceId ===
                  organization.pendingProductionInstanceId &&
                instanceResponse.instanceState === "RUNNING"
            ),
            map(organization =>
              deploymentsActions.postDeployment(
                instanceResponse.organization!,
                instanceResponse.instanceId
              )
            )
          )
        )
      )
    )
  );

export default combineEpics(
  checkForNonrunningInstances,
  deleteInstance,
  fetchInstances,
  init,
  makeInstancesProduction,
  fetchInstancesAndDeploymentsSuccess,
  fetchInstancesAndEc2InstancesSuccess
);
