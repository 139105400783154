import { Theme } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import React, { PureComponent } from "react";

import { EdgeConfig } from "../features/edgeConfigs/models";
import { TableCellCentered, TableCellCommon } from "./Instances";

import "./Instances.css";

export type ConfigsRowProps = EdgeConfig & {
  sameAsPreviousOrg: boolean;
  isSelected: boolean;
  handleSelection: (organization: string, name: string) => void;
};

export type ConfigsRowState = {};

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    centered: { ...TableCellCentered },
    tableBodyRow: {
      /* height: tableRowHeight */
      height: 37,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: `rgba(0, 0, 64, 0.08) !important`
      }
    },
    tableCell: {
      ...TableCellCommon,
      borderTop: "solid 1px #e0e0e0",
      borderBottom: "none",
      fontSize: "0.75rem",
      whiteSpace: "nowrap"
    },
    tableCellNoBorder: { borderTop: "none" }
  });

class ConfigsRow extends PureComponent<
  ConfigsRowProps & WithStyles<typeof styles>,
  ConfigsRowState
> {
  public state: ConfigsRowState = {};

  public render() {
    const {
      sameAsPreviousOrg,
      organization,
      name,
      caCertFilename,
      clientCertFilename,
      clientKeyFilename,
      isSelected,
      classes
    } = this.props;
    const {} = this.state;

    return (
      <TableRow
        classes={{
          root: classes.tableBodyRow
        }}
        hover={true}
        onClick={this.handleSelection}
        selected={isSelected}
      >
        <TableCell
          classes={{
            root: classNames(
              classes.tableCell,
              sameAsPreviousOrg ? classes.tableCellNoBorder : ""
            )
          }}
        >
          {organization}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.tableCell,
              sameAsPreviousOrg ? classes.tableCellNoBorder : ""
            )
          }}
        >
          {name}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.tableCell,
              sameAsPreviousOrg ? classes.tableCellNoBorder : ""
            )
          }}
        >
          {caCertFilename}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.tableCell,
              sameAsPreviousOrg ? classes.tableCellNoBorder : ""
            )
          }}
        >
          {clientCertFilename}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.tableCell,
              sameAsPreviousOrg ? classes.tableCellNoBorder : ""
            )
          }}
        >
          {clientKeyFilename}
        </TableCell>
      </TableRow>
    );
  }

  private handleSelection = () => {
    this.props.handleSelection(this.props.organization, this.props.name);
  };
}

export default withStyles(styles)(ConfigsRow);
