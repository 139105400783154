import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import {
  FETCH_ADMINS_FAILURE,
  FETCH_ADMINS_SUCCESS,
  MAKE_ADMIN,
  MAKE_ADMIN_FAILURE,
  REVOKE_ADMIN,
  REVOKE_ADMIN_FAILURE
} from "./constants";
import { Admin } from "./models";

export type AdminsAction = ActionType<typeof actions>;

export type AdminsState = {
  bySub: {
    [sub: string]: Admin;
  };
  hasFetchedInitialAdmins: boolean;
};

export default combineReducers<AdminsState, AdminsAction>({
  bySub: (state = {}, action) => {
    switch (action.type) {
      case FETCH_ADMINS_SUCCESS:
        const adminResponses = action.payload.adminResponses;
        const newState = adminResponses.reduce((state, adminResponse) => {
          if (state.hasOwnProperty(adminResponse.sub)) {
            return {
              ...state,
              [adminResponse.sub]: {
                ...state[adminResponse.sub],
                ...adminResponse
              }
            };
          } else {
            return {
              ...state,
              [adminResponse.sub]: {
                ...adminResponse,
                isRevokingAdmin: false
              }
            };
          }
        }, state);

        // Check if we have existing instances in our list that aren't in the newly fetched list.
        // Any found, should be removed.
        return Object.keys(newState).reduce((state, sub) => {
          const adminResponse = adminResponses.findIndex(ar => {
            return ar.sub === sub;
          });
          if (adminResponse !== -1) {
            return state;
          }
          const newState = { ...state };
          delete newState[sub];
          return newState;
        }, newState);

      case FETCH_ADMINS_FAILURE:
        return {};

      case MAKE_ADMIN:
      case MAKE_ADMIN_FAILURE:
      case REVOKE_ADMIN:
      case REVOKE_ADMIN_FAILURE:
        const sub = action.payload.sub;
        const admin = state[sub];
        if (!admin) {
          return state;
        }
        return {
          ...state,
          [sub]: {
            ...admin,
            isRevokingAdmin:
              action.type === MAKE_ADMIN || action.type === REVOKE_ADMIN
          }
        };

      default:
        return state;
    }
  },

  hasFetchedInitialAdmins: (state = false, action) => {
    switch (action.type) {
      case FETCH_ADMINS_SUCCESS:
        return true;
      default:
        return state;
    }
  }
});
