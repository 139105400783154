import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import { DeploymentsAction } from "../deployments";
import {
  POST_DEPLOYMENT,
  POST_DEPLOYMENT_FAILURE,
  POST_DEPLOYMENT_SUCCESS
} from "../deployments/constants";
import { DomainsAction } from "../domains";
import { InstancesAction } from "../instances";
import * as actions from "./actions";
import {
  FETCH_ORGANIZATION_USAGE,
  FETCH_ORGANIZATION_USAGE_FAILURE,
  FETCH_ORGANIZATION_USAGE_SUCCESS,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_AND_DEPLOYMENTS_SUCCESS,
  FETCH_ORGANIZATIONS_AND_EDGE_CONFIGS_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_SUCCESS,
  LAUNCH_NEW_INSTANCE,
  LAUNCH_NEW_INSTANCE_FAILURE,
  LAUNCH_NEW_INSTANCE_SUCCESS
} from "./constants";
import { Organization, ProductionStatus } from "./models";

export type OrganizationsAction = ActionType<typeof actions>;

export type OrganizationsState = {
  byName: {
    [name: string]: Organization;
  };
  hasFetchedInitialOrganizations: boolean;
  hasFetchedOrganizations: boolean;
};

export default combineReducers<
  OrganizationsState,
  OrganizationsAction | InstancesAction | DeploymentsAction | DomainsAction
>({
  byName: (state = {}, action) => {
    switch (action.type) {
      case FETCH_ORGANIZATIONS_SUCCESS: {
        return action.payload.reduce((state, organizationResponse) => {
          let newOrganization;
          if (organizationResponse.name in state) {
            newOrganization = {
              ...state[organizationResponse.name],
              ...organizationResponse
            };
          } else {
            newOrganization = {
              ...organizationResponse,
              productionStatus: "none" as ProductionStatus,
              hasEdgeConfig: false,
              old_isPendingMakeProductionOrg: false,
              pendingProductionInstanceId: null,
              isFetchingUsage: false,
              numDomainUsagesToFetch: 0
            };
          }
          return {
            ...state,
            [newOrganization.name]: newOrganization
          };
        }, state);
      }

      case FETCH_ORGANIZATIONS_FAILURE: {
        return {};
      }

      case FETCH_ORGANIZATIONS_AND_DEPLOYMENTS_SUCCESS: {
        // Loop over each deployment, and see if there is a corresponding organization.
        // If so, then mark the organization has having a production instance.
        // Furthermore, the organization can no longer be pending production.
        const deployments = action.payload.deploymentsState.deployments;

        return Object.keys(state).reduce((accState, organizationName) => {
          const organization = { ...accState[organizationName] };
          const deployment = deployments.find(
            d => d.organization === organizationName
          );
          if (!deployment) {
            organization.productionStatus = "none";
          } else {
            organization.productionStatus = "production";
            // If the org was pending a production instanceId, then clear it.
            if (
              organization.pendingProductionInstanceId !== deployment.instanceId
            ) {
              organization.old_isPendingMakeProductionOrg = false;
              organization.pendingProductionInstanceId = null;
            }
          }
          return {
            ...accState,
            [organization.name]: organization
          };
        }, state);
      }

      case FETCH_ORGANIZATIONS_AND_EDGE_CONFIGS_SUCCESS: {
        return action.payload.edgeConfigsState.edgeConfigs.reduce(
          (accState, edgeConfigResponse) => {
            const organization = {
              ...accState[edgeConfigResponse.organization]
            };
            if (!organization) {
              return accState;
            }
            organization.hasEdgeConfig = true;
            return {
              ...accState,
              [organization.name]: organization
            };
          },
          state
        );
      }

      case LAUNCH_NEW_INSTANCE: {
        const organization = state[action.payload];
        if (!organization) {
          return state;
        }
        return {
          ...state,
          [organization.name]: {
            ...organization,
            productionStatus: "pending",
            old_isPendingMakeProductionOrg: true
          }
        };
      }

      case LAUNCH_NEW_INSTANCE_SUCCESS: {
        if (!action.payload.organization) {
          return state;
        }
        const organization = state[action.payload.organization];
        if (!organization) {
          return state;
        }
        return {
          ...state,
          [organization.name]: {
            ...organization,
            pendingProductionInstanceId: action.payload.instanceId
          }
        };
      }

      case LAUNCH_NEW_INSTANCE_FAILURE: {
        const organization = state[action.payload.organizationName];
        if (!organization) {
          return state;
        }
        return {
          ...state,
          [organization.name]: {
            ...organization,
            pendingProductionInstanceId: null
          }
        };
      }

      case POST_DEPLOYMENT: {
        const organization = { ...state[action.payload.organization] };
        if (!organization) {
          return state;
        }
        organization.productionStatus = "pending";
        organization.old_isPendingMakeProductionOrg = true;
        return {
          ...state,
          [organization.name]: organization
        };
      }

      case POST_DEPLOYMENT_SUCCESS: {
        // If an organization was asked to add a new instance and make it production,
        // then check if that happened after deployments have been fetched.
        const deploymentResponse = action.payload.deploymentResponse;
        const organization = state[deploymentResponse.organization];
        if (!organization) {
          return state;
        }
        return {
          ...state,
          [organization.name]: {
            ...organization,
            pendingProductionInstanceId: deploymentResponse.instanceId
          }
        };
      }

      case POST_DEPLOYMENT_FAILURE: {
        const organization = state[action.payload.organization];
        if (!organization) {
          return state;
        }
        return {
          ...state,
          [organization.name]: {
            ...organization,
            productionStatus: "none",
            pendingProductionInstanceId: null
          }
        };
      }

      case FETCH_ORGANIZATION_USAGE: {
        const organizationName = action.payload.organizationName;
        return {
          ...state,
          [organizationName]: {
            ...state[organizationName],
            isFetchingUsage: true,
            numDomainUsagesToFetch: action.payload.numDomains
          }
        };
      }

      case FETCH_ORGANIZATION_USAGE_SUCCESS: {
        const organizationName2 = action.payload;
        const numDomainUsagesToFetch =
          state[organizationName2].numDomainUsagesToFetch - 1;
        return {
          ...state,
          [organizationName2]: {
            ...state[organizationName2],
            isFetchingUsage: numDomainUsagesToFetch > 0,
            numDomainUsagesToFetch:
              numDomainUsagesToFetch < 0 ? 0 : numDomainUsagesToFetch
          }
        };
      }

      // TODO Handle this case
      case FETCH_ORGANIZATION_USAGE_FAILURE: {
        return state;
      }

      default: {
        return state;
      }
    }
  },

  hasFetchedInitialOrganizations: (state = false, action) => {
    switch (action.type) {
      case FETCH_ORGANIZATIONS_SUCCESS:
        return true;
      default:
        return state;
    }
  },

  hasFetchedOrganizations: (state = false, action) => {
    switch (action.type) {
      case FETCH_ORGANIZATIONS: {
        return false;
      }
      case FETCH_ORGANIZATIONS_SUCCESS: {
        return true;
      }
      default: {
        return state;
      }
    }
  }
});
