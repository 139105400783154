import { CircularProgress, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import React, { PureComponent } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
// Highlight.js Style examples: https://highlightjs.org/static/demo/
import { vs2015 as codeStyle } from "react-syntax-highlighter/dist/styles/hljs";

import { EdgeConfigsState } from "../features/edgeConfigs";
import { EdgeConfig } from "../features/edgeConfigs/models";
import ConfigsRow from "./ConfigsRow";
import { TableCellCommon, TableCommon } from "./Instances";

import "./Configs.css";
import "./Instances.css";

export type ConfigsProps = EdgeConfigsState & {};

export type ConfigsState = {
  selectedEdgeConfig: EdgeConfig | null;
};

// Override some of the default stylings because it interferes with our own styling.
codeStyle.hljs.padding = "";
// codeStyle.hljs.backgroundColor = "";

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    table: {
      ...TableCommon
    },
    instancesTableCellCommon: {
      ...TableCellCommon,
      position: "sticky",
      top: 0,
      zIndex: 10,
      backgroundColor: "#f3f3f3",
      boxShadow: "inset 0 1px 0 #e4e4e4, inset 0 -1px 0 #e4e4e4",
      color: "rgba(0, 0, 0, 0.75)",
      whiteSpace: "nowrap"
    }
  });

/**
 * Compare function for sorting edgeConfigs table.
 * Sort by organization, then name.
 */
function compareEdgeConfigs(a: EdgeConfig, b: EdgeConfig) {
  const aOrg = a.organization ? a.organization.toLowerCase() : "";
  const bOrg = b.organization ? b.organization.toLowerCase() : "";
  if (aOrg < bOrg) {
    return -1;
  } else if (aOrg > bOrg) {
    return 1;
  } else {
    return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
  }
}

class Configs extends PureComponent<
  ConfigsProps & WithStyles<typeof styles>,
  ConfigsState
> {
  public state: ConfigsState = {
    selectedEdgeConfig: null
  };

  private prevOrgName: string | null = null;

  public render() {
    const { edgeConfigs, hasFetchedInitialEdgeConfigs, classes } = this.props;
    const {} = this.state;

    return (
      <div className="Configs">
        <div className="Configs-users">
          <Table classes={{ root: classes.table }}>
            <TableHead>
              <TableRow>
                <TableCell
                  classes={{
                    root: classNames(classes.instancesTableCellCommon)
                  }}
                >
                  organization
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.instancesTableCellCommon)
                  }}
                >
                  config name
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.instancesTableCellCommon)
                  }}
                >
                  ca cert
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.instancesTableCellCommon)
                  }}
                >
                  client cert
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.instancesTableCellCommon)
                  }}
                >
                  client key
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hasFetchedInitialEdgeConfigs &&
                edgeConfigs.sort(compareEdgeConfigs).map(edgeConfig => {
                  // Don't draw a border if the org is the same, so it makes all configs
                  // for an organization look more grouped together in the table.
                  const sameAsPreviousOrg =
                    edgeConfig.organization === this.prevOrgName;
                  this.prevOrgName = edgeConfig.organization;

                  return (
                    <ConfigsRow
                      key={`${edgeConfig.organization}-${edgeConfig.name}`}
                      {...edgeConfig}
                      sameAsPreviousOrg={sameAsPreviousOrg}
                      isSelected={
                        this.state.selectedEdgeConfig !== null &&
                        this.state.selectedEdgeConfig.organization ===
                          edgeConfig.organization &&
                        this.state.selectedEdgeConfig.name === edgeConfig.name
                      }
                      handleSelection={this.handleSelection}
                    />
                  );
                })}
            </TableBody>
          </Table>
          {!hasFetchedInitialEdgeConfigs && (
            <div className="Instances-progress">
              <CircularProgress size={64} />
            </div>
          )}
        </div>
        <div className="Configs-config">
          {this.state.selectedEdgeConfig ? (
            <SyntaxHighlighter
              language="json"
              style={codeStyle}
              className={`Configs-code`}
              CodeTag="div"
            >
              {JSON.stringify(
                JSON.parse(this.state.selectedEdgeConfig.config),
                null,
                2
              )}
            </SyntaxHighlighter>
          ) : (
            <Typography align="center">
              Click a row to see the configuration
            </Typography>
          )}
        </div>
      </div>
    );
  }

  private handleSelection = (organization: string, name: string) => {
    const selectedEdgeConfig = this.props.edgeConfigs.find(
      ec => ec.organization === organization && ec.name === name
    );
    if (selectedEdgeConfig) {
      this.setState({ selectedEdgeConfig: { ...selectedEdgeConfig } });
    }
  };
}

export default withStyles(styles)(Configs);
