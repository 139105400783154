import { combineReducers } from "redux";

import { adminsReducer } from "../features/admins";
import { authConstants, authReducer } from "../features/auth";
import { deploymentsReducer } from "../features/deployments";
import { domainsReducer } from "../features/domains";
import { ec2InstancesReducer } from "../features/ec2Instances";
import { edgeConfigsReducer } from "../features/edgeConfigs";
import { instancesReducer } from "../features/instances";
import { organizationsReducer } from "../features/organizations";
import { usersReducer } from "../features/users";
import { RootAction } from "./types";

const appReducer = combineReducers({
  admins: adminsReducer,
  auth: authReducer,
  deployments: deploymentsReducer,
  domains: domainsReducer,
  ec2Instances: ec2InstancesReducer,
  edgeConfigs: edgeConfigsReducer,
  instances: instancesReducer,
  organizations: organizationsReducer,
  users: usersReducer
});

export default (state: any, action: RootAction) => {
  if (action.type === authConstants.SIGNED_OUT) {
    state = undefined;
  }
  return appReducer(state, action);
};
