import { combineEpics } from "redux-observable";

import admins from "../features/admins/epics";
import app from "../features/app/epics";
import deployments from "../features/deployments/epics";
import domains from "../features/domains/epics";
import ec2Instances from "../features/ec2Instances/epics";
import edgeConfigs from "../features/edgeConfigs/epics";
import instances from "../features/instances/epics";
import organizations from "../features/organizations/epics";
import users from "../features/users/epics";

export default combineEpics(
  admins,
  app,
  deployments,
  domains,
  ec2Instances,
  edgeConfigs,
  instances,
  organizations,
  users
);
