import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import Configs, { ConfigsProps } from "../components/Configs";
import { RootState } from "../store";

const ConfigContainer = (props: ConfigsProps) => {
  return <Configs {...props} />;
};

const mapStateToProps = (state: RootState) => {
  return {
    edgeConfigs: state.edgeConfigs.edgeConfigs,
    hasFetchedInitialEdgeConfigs: state.edgeConfigs.hasFetchedInitialEdgeConfigs
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigContainer);
