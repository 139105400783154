import { Ec2InstanceState, InstanceResponse } from "../../services/api";

export type Instance = InstanceResponse & {
  hasFetchedEc2Instance: boolean;
  ec2InstanceState: Ec2InstanceState | null;
  publicIpAddress: string | null;
  hasFetchedDeployment: boolean;
  isProduction: boolean;
  isPendingDelete: boolean;
};

const VERSION_REGEX = /(\d+)-(\d+)-(\d+)/;

export function compareVersions(a: string | null, b: string | null) {
  if (a === null || b === null || a === b) {
    return 0;
  }
  const aParts = VERSION_REGEX.exec(a);
  const bParts = VERSION_REGEX.exec(b);
  if (aParts === null || bParts === null) {
    return 0;
  }
  for (let i = 1; i < 4; i++) {
    if (+aParts[i] < +bParts[i]) {
      return -1;
    } else if (+aParts[i] > +bParts[i]) {
      return 1;
    }
  }
  return 0;
}
