import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import {
  FETCH_EC2_INSTANCES,
  FETCH_EC2_INSTANCES_FAILURE,
  FETCH_EC2_INSTANCES_SUCCESS
} from "./constants";
import { Ec2Instance } from "./models";

export type Ec2InstancesAction = ActionType<typeof actions>;

export type Ec2InstancesState = {
  byInstanceId: {
    [instanceId: string]: Ec2Instance;
  };
  hasFetchedInitialEc2Instances: boolean;
  hasFetchedEc2Instances: boolean;
};

export default combineReducers<Ec2InstancesState, Ec2InstancesAction>({
  byInstanceId: (state = {}, action) => {
    switch (action.type) {
      case FETCH_EC2_INSTANCES_SUCCESS:
        return action.payload.reduce((state, ec2InstanceResponse) => {
          if (state.hasOwnProperty(ec2InstanceResponse.instanceId)) {
            return {
              ...state,
              [ec2InstanceResponse.instanceId]: {
                ...state[ec2InstanceResponse.instanceId],
                ...ec2InstanceResponse
              }
            };
          } else {
            return {
              ...state,
              [ec2InstanceResponse.instanceId]: { ...ec2InstanceResponse }
            };
          }
        }, state);

      case FETCH_EC2_INSTANCES_FAILURE:
        return {};

      default:
        return state;
    }
  },

  hasFetchedInitialEc2Instances: (state = false, action) => {
    switch (action.type) {
      case FETCH_EC2_INSTANCES_SUCCESS:
        return true;
      default:
        return state;
    }
  },

  hasFetchedEc2Instances: (state = false, action) => {
    switch (action.type) {
      case FETCH_EC2_INSTANCES:
        return false;
      case FETCH_EC2_INSTANCES_SUCCESS:
        return true;
      default:
        return state;
    }
  }
});
