import Typography, { TypographyProps } from "@material-ui/core/Typography";
import React from "react";

import "./Link.css";

export type LinkProps = {
  className?: string;
} & TypographyProps;

const Link = ({ className, ...props }: LinkProps) => (
  <Typography {...props} className={`Link ${className || ""}`} />
);

export default Link;
