import { action } from "typesafe-actions";

import { InstanceResponse, OrganizationResponse } from "../../services/api";
import { DeploymentsState } from "../deployments";
import { EdgeConfigsState } from "../edgeConfigs";
import {
  FETCH_ORGANIZATION_USAGE,
  FETCH_ORGANIZATION_USAGE_FAILURE,
  FETCH_ORGANIZATION_USAGE_SUCCESS,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_AND_DEPLOYMENTS_SUCCESS,
  FETCH_ORGANIZATIONS_AND_EDGE_CONFIGS_SUCCESS,
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_SUCCESS,
  LAUNCH_NEW_INSTANCE,
  LAUNCH_NEW_INSTANCE_FAILURE,
  LAUNCH_NEW_INSTANCE_SUCCESS
} from "./constants";

export const fetchOrganizations = () => action(FETCH_ORGANIZATIONS);
export const fetchOrganizationsSuccess = (
  organizationResponses: OrganizationResponse[]
) => action(FETCH_ORGANIZATIONS_SUCCESS, organizationResponses);
export const fetchOrganizationsFailure = (error: any) =>
  action(FETCH_ORGANIZATIONS_FAILURE, { error });

export const launchNewInstance = (organizationName: string) =>
  action(LAUNCH_NEW_INSTANCE, organizationName);
export const launchNewInstanceSuccess = (newInstance: InstanceResponse) =>
  action(LAUNCH_NEW_INSTANCE_SUCCESS, newInstance);
export const launchNewInstanceFailure = (
  organizationName: string,
  error: any
) => action(LAUNCH_NEW_INSTANCE_FAILURE, { organizationName, error });

export const fetchOrganizationUsage = (
  organizationName: string,
  numDomains: number
) => action(FETCH_ORGANIZATION_USAGE, { organizationName, numDomains });
export const fetchOrganizationUsageSuccess = (organizationName: string) =>
  action(FETCH_ORGANIZATION_USAGE_SUCCESS, organizationName);
export const fetchOrganizationUsageFailure = (
  organizationName: string,
  error: any
) => action(FETCH_ORGANIZATION_USAGE_FAILURE, { organizationName, error });

export const fetchOrganizationsAndDeploymentsSuccess = (
  deploymentsState: DeploymentsState
) =>
  action(FETCH_ORGANIZATIONS_AND_DEPLOYMENTS_SUCCESS, {
    deploymentsState
  });

export const fetchOrganizationsAndEdgeConfigsSuccess = (
  edgeConfigsState: EdgeConfigsState
) =>
  action(FETCH_ORGANIZATIONS_AND_EDGE_CONFIGS_SUCCESS, {
    edgeConfigsState
  });
