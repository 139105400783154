import { action } from "typesafe-actions";

import { EdgeConfigsResponse } from "../../services/api";
import {
  FETCH_EDGE_CONFIGS,
  FETCH_EDGE_CONFIGS_FAILURE,
  FETCH_EDGE_CONFIGS_SUCCESS
} from "./constants";

export const fetchEdgeConfigs = () => action(FETCH_EDGE_CONFIGS);
export const fetchEdgeConfigsSuccess = (
  edgeConfigsResponse: EdgeConfigsResponse[]
) => action(FETCH_EDGE_CONFIGS_SUCCESS, edgeConfigsResponse);
export const fetchEdgeConfigsFailure = (error: any) =>
  action(FETCH_EDGE_CONFIGS_FAILURE, { error });
