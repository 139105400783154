import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import Users, { UsersProps } from "../components/Users";
import { adminsActions } from "../features/admins";
import { RootState } from "../store";

const UsersContainer = (props: UsersProps) => {
  return <Users {...props} />;
};

const mapStateToProps = (state: RootState) => {
  return {
    usersState: state.users,
    adminsState: state.admins
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  makeAdmin: (sub: string) => {
    dispatch(adminsActions.makeAdmin(sub));
  },
  revokeAdmin: (sub: string) => {
    dispatch(adminsActions.revokeAdmin(sub));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersContainer);
