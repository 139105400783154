import { Theme } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { PureComponent } from "react";

import { DomainUsage } from "../features/domains/models";

import "./Domains.css";

export type DomainUsageProps = {
  usage: DomainUsage;
};

export type DomainsUsageState = {};

type UsageRecord = {
  dateStr: string;
  bytesWritten: number;
};

const styles = ({ palette, spacing }: Theme) => createStyles({});

// Used for sorting usages in descending order (i.e. recent usages first)
function usageComparator(a: UsageRecord, b: UsageRecord) {
  // What's with the '+' symbols used here? See:
  // https://github.com/Microsoft/TypeScript/issues/5710#issuecomment-157886246
  const start = +new Date(a.dateStr);
  return +new Date(b.dateStr) - start;
}

class DomainUsageDisplay extends PureComponent<
  DomainUsageProps & WithStyles<typeof styles>,
  DomainsUsageState
> {
  public state: DomainsUsageState = {};

  public render() {
    const { usage } = this.props;

    const usageOrdered: UsageRecord[] = [];
    let totalBytesWritten = 0;
    Object.keys(usage).forEach(dateStr => {
      const bytesWritten = usage[dateStr];
      usageOrdered.push({
        dateStr,
        bytesWritten
      });
      totalBytesWritten += bytesWritten;
    });
    usageOrdered.sort(usageComparator);

    return usageOrdered.length > 0 ? (
      <table className="usage">
        <tbody>
          {usageOrdered.map(usage => (
            <tr key={usage.dateStr}>
              <td>
                <code>{usage.dateStr}</code>
              </td>
              <td>
                <code>
                  {Number(usage.bytesWritten).toLocaleString()}
                  <span className="usageUnits">bytes</span>
                </code>
              </td>
            </tr>
          ))}
          {
            <tr className="total">
              <td>
                <code>Total</code>
              </td>
              <td>
                <code>
                  {Number(totalBytesWritten).toLocaleString()}
                  <span className="usageUnits">bytes</span>
                </code>
              </td>
            </tr>
          }
        </tbody>
      </table>
    ) : (
      <span>No usage data</span>
    );
  }
}

export default withStyles(styles)(DomainUsageDisplay);
