import { CircularProgress, Theme, Tooltip } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ErrorIcon from "@material-ui/icons/Error";
import classNames from "classnames";
import React, { PureComponent } from "react";

import { Domain } from "../features/domains/models";
import { Organization } from "../features/organizations/models";
import ProgressButton from "./core/ProgressButton";
import DomainUsageDisplay from "./DomainUsageDisplay";
import { borderColor, TableCellCentered, TableCellCommon } from "./Instances";

import "./Instances.css";

export type DomainsRowProps = {
  domain: Domain;
  organization: Organization | undefined;
  isSameAsPreviousOrg: boolean;
  fetchOrganizationUsage: (organization: string) => void;
};

export type DomainsRowState = {};

export const circularProgressSize = 12;

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    tableRow: {
      // height: 164
    },
    tableCell: {
      ...TableCellCommon,
      borderTop: `solid 1px ${borderColor}`,
      borderBottom: "none",
      fontSize: "0.75rem",
      whiteSpace: "nowrap",
      verticalAlign: "top",
      paddingTop: 15
    },
    tableCellNoBorder: {
      borderTop: "none"
    },
    instancesTableButton: {
      // TODO Change name and make generic for all screens
      fontSize: "0.9em",
      minHeight: 24,
      top: -7
    },
    centered: { ...TableCellCentered },
    organizationError: {
      color: red[700],
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    errorIcon: {
      color: red[700],
      marginLeft: 10,
      position: "relative"
    }
  });

class DomainsRow extends PureComponent<
  DomainsRowProps & WithStyles<typeof styles>,
  DomainsRowState
> {
  public state: DomainsRowState = {};

  public render() {
    const { domain, isSameAsPreviousOrg, organization, classes } = this.props;
    const {} = this.state;

    let usageCell: string | JSX.Element = "";
    if (!domain.usage) {
      if (domain.isFetchingUsage) {
        usageCell = <CircularProgress size={12} />;
      } else if (!isSameAsPreviousOrg) {
        usageCell = (
          <ProgressButton
            variant="contained"
            color="primary"
            size="small"
            classes={{
              root: classes.instancesTableButton
            }}
            disabled={false}
            isPending={organization ? organization.isFetchingUsage : false}
            didSucceed={false}
            onClick={this.fetchOrganizationUsage}
          >
            Get usage
          </ProgressButton>
        );
      }
    } else {
      usageCell = <DomainUsageDisplay usage={domain.usage} />;
    }

    return (
      <TableRow
        classes={{
          root: classNames(classes.tableRow)
        }}
      >
        <TableCell
          classes={{
            root: classNames(
              classes.tableCell,
              !organization ? classes.organizationError : "",
              isSameAsPreviousOrg ? classes.tableCellNoBorder : ""
            )
          }}
        >
          {!isSameAsPreviousOrg && domain.organization}
          {!isSameAsPreviousOrg && !organization && (
            <Tooltip
              placement="right"
              title="This organization does not exist"
              disableFocusListener={true}
            >
              <ErrorIcon classes={{ root: classes.errorIcon }} />
            </Tooltip>
          )}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.tableCell,
              isSameAsPreviousOrg ? classes.tableCellNoBorder : ""
            )
          }}
        >
          {domain.domainName}
        </TableCell>
        <TableCell
          classes={{
            root: classNames(
              classes.tableCell,
              isSameAsPreviousOrg ? classes.tableCellNoBorder : ""
            )
          }}
        >
          {usageCell}
        </TableCell>
      </TableRow>
    );
  }

  private fetchOrganizationUsage = () => {
    this.props.fetchOrganizationUsage(this.props.domain.organization);
  };
}

export default withStyles(styles)(DomainsRow);
