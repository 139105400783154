import { CircularProgress, Theme, Tooltip } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { mdiAccountStar, mdiCloudAlert, mdiFileCancel } from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import filesize from "filesize";
import moment from "moment";
import React, { PureComponent } from "react";

import { Domain } from "../features/domains/models";
import { Organization } from "../features/organizations/models";
import { User } from "../features/users/models";
import ProgressButton from "./core/ProgressButton";
import { borderColor, TableCellCentered, TableCellCommon } from "./Instances";

import "./Instances.css";

export type InstancesOrganizationRowProps = {
  organization: Organization | null;
  domains: Domain[] | null;
  user: User | null;
  hasFetchedInitialAdmins: boolean;
  handleLaunchNewInstance: (organization: string) => void;
};

export type InstancesOrganizationRowState = {};

export const circularProgressSize = 12;

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    tableSeparatorRow: {
      // height: 48
    },
    tableOrganizationRow: {
      // height: 164
    },
    tableCell: {
      ...TableCellCommon,
      borderTop: `solid 1px ${borderColor}`,
      borderBottom: `solid 1px ${borderColor}`,
      fontSize: "0.75rem"
    },
    organizationHeader: {
      backgroundColor: "#f9f9f9",
      borderTopColor: "#ffe3e3",
      borderBottomColor: "#ffe3e3"
    },
    orphanHeader: {
      backgroundColor: "#fff9f9"
    },
    orphanCell: {
      color: red[700]
    },
    instancesTableButton: {
      fontSize: "0.9em",
      minHeight: 24
    },
    centered: { ...TableCellCentered },
    noProdError: {},
    errorIcon: {
      // TODO Make this a variable as other files have the same style
      color: red[700],
      marginLeft: 15,
      marginRight: 5,
      position: "relative"
      // top: 1
    }
  });

class InstancesOrganizationRow extends PureComponent<
  InstancesOrganizationRowProps & WithStyles<typeof styles>,
  InstancesOrganizationRowState
> {
  public state: InstancesOrganizationRowState = {};

  public render() {
    const {
      organization,
      domains,
      user,
      hasFetchedInitialAdmins,
      classes
    } = this.props;
    const {} = this.state;

    // TODO Standardize and make constants for attributes like size and color.
    const edgeConfigStatus: JSX.Element | null =
      organization && !organization.hasEdgeConfig ? (
        <div className="chip chip-warning">
          <div>
            <Icon
              className="chip-icon"
              path={mdiFileCancel}
              size="22px"
              color="rgba(0, 0, 0, 0.75)"
            />
            <div>No edge config</div>
          </div>
        </div>
      ) : null;

    const productionStatus: JSX.Element | null =
      organization && organization.productionStatus !== "production" ? (
        <div className="chip chip-warning">
          <div>
            <Icon
              className="chip-icon"
              path={mdiCloudAlert}
              size="22px"
              color="rgba(0, 0, 0, 0.75)"
            />
            <div>No production instance</div>
          </div>
        </div>
      ) : null;

    const isFetchingUsage = organization ? organization.isFetchingUsage : false;

    const totalUsage = domains
      ? domains.reduce((acc, domain) => {
          if (domain.usage === null) {
            return acc;
          }
          if (acc === -1) {
            acc = 0;
          }
          return (
            acc +
            Object.keys(domain.usage).reduce((accDomainUsage, dateStr) => {
              return accDomainUsage + domain.usage![dateStr];
            }, 0)
          );
        }, -1)
      : -1;
    const totalUsageStr = totalUsage >= 0 ? filesize(totalUsage) : "?";

    let lastSignedIn: string | JSX.Element = "unknown";
    if (user) {
      if (user.lastSignedIn) {
        lastSignedIn = moment(user.lastSignedIn).fromNow();
      }
    } else {
      lastSignedIn = <CircularProgress size={12} />;
    }

    return (
      <React.Fragment>
        <TableRow
          classes={{
            root: classNames(classes.tableSeparatorRow)
          }}
        >
          <TableCell colSpan={13} />
        </TableRow>
        <TableRow
          classes={{
            root: classNames(
              classes.tableOrganizationRow,
              organization ? classes.organizationHeader : classes.orphanHeader
            )
          }}
        >
          <TableCell
            classes={{
              root: classNames(
                classes.tableCell,
                !organization ? classes.orphanCell : ""
              )
            }}
            colSpan={13}
          >
            <div className="Instances-orgHeader">
              <div className="Instances-orgHeader-left">
                <div className="Instances-orgHeader-left-inner">
                  <div>
                    <span className="orgname">
                      {organization ? organization.name : "orphaned"}
                    </span>
                  </div>
                </div>
              </div>
              {organization && (
                <div className="Instances-orgHeader-right">
                  <div className="Instances-orgHeader-right-inner">
                    {edgeConfigStatus}
                    {productionStatus}
                    {user && user.isAdmin && (
                      <div className="chip">
                        <div>
                          <Tooltip
                            placement="top"
                            title="Admin user"
                            disableFocusListener={true}
                          >
                            <Icon
                              path={mdiAccountStar}
                              size="22px"
                              color="rgba(0, 0, 0, 0.54)"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    )}
                    {!hasFetchedInitialAdmins && (
                      <div className="chip">
                        <div>
                          <CircularProgress size={12} />
                        </div>
                      </div>
                    )}
                    <div className="chip">
                      <div className="chip-label">Last sign in:</div>
                      <div>{lastSignedIn}</div>
                    </div>
                    <div className="chip">
                      <div className="chip-label">Usage:</div>
                      <div>
                        {!isFetchingUsage && totalUsageStr}
                        {isFetchingUsage && <CircularProgress size={12} />}
                      </div>
                    </div>
                    <div>
                      <ProgressButton
                        variant="contained"
                        color="primary"
                        size="small"
                        classes={{
                          root: classes.instancesTableButton
                        }}
                        disabled={false}
                        isPending={organization.productionStatus === "pending"}
                        didSucceed={false}
                        onClick={this.handleLaunchNewInstance}
                      >
                        Upgrade
                      </ProgressButton>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  private handleLaunchNewInstance = () => {
    const { organization } = this.props;
    if (organization) {
      this.props.handleLaunchNewInstance(organization.name);
    } else {
      // tslint:disable-next-line:no-console
      console.error("This should never happen");
    }
  };
}

export default withStyles(styles)(InstancesOrganizationRow);
