import { combineEpics, Epic } from "redux-observable";
import { concat, from, of } from "rxjs";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import { organizationsActions } from ".";
import * as api from "../../services/api";
import { RootAction, RootState } from "../../store";
import { SIGNED_IN } from "../auth/constants";
import { FETCH_DEPLOYMENTS_SUCCESS } from "../deployments/constants";
import { domainsActions } from "../domains";
import { FETCH_DOMAIN_USAGE_SUCCESS } from "../domains/constants";
import { FETCH_EDGE_CONFIGS_SUCCESS } from "../edgeConfigs/constants";
import { instancesActions } from "../instances";
import * as actions from "./actions";
import {
  FETCH_ORGANIZATION_USAGE,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
  LAUNCH_NEW_INSTANCE
} from "./constants";

export const init: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(SIGNED_IN)),
    mergeMap(_ => of(actions.fetchOrganizations()))
  );

export const fetchOrganizations: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_ORGANIZATIONS)),
    mergeMap(_ =>
      from(api.fetchOrganizations()).pipe(
        mergeMap(organizations =>
          of(actions.fetchOrganizationsSuccess(organizations))
        ),
        catchError(err => of(actions.fetchOrganizationsFailure(err)))
      )
    )
  );

// Do processing that requires both instance and deployment data.
// TODO Add a test
export const fetchOrganizationsAndDeploymentsSuccess: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      action =>
        (isOfType(FETCH_ORGANIZATIONS_SUCCESS)(action) ||
          isOfType(FETCH_DEPLOYMENTS_SUCCESS)(action)) &&
        (state$.value.organizations.hasFetchedOrganizations &&
          state$.value.deployments.hasFetchedDeployments)
    ),
    mergeMap(_ =>
      of(
        actions.fetchOrganizationsAndDeploymentsSuccess(
          state$.value.deployments
        )
      )
    )
  );

// Do processing that requires both instance and edge config data.
// TODO Add a test
export const fetchOrganizationsAndEdgeConfigsSuccess: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      action =>
        (isOfType(FETCH_ORGANIZATIONS_SUCCESS)(action) ||
          isOfType(FETCH_EDGE_CONFIGS_SUCCESS)(action)) &&
        (state$.value.organizations.hasFetchedOrganizations &&
          state$.value.edgeConfigs.hasFetchedInstances)
    ),
    mergeMap(_ =>
      of(
        actions.fetchOrganizationsAndEdgeConfigsSuccess(
          state$.value.edgeConfigs
        )
      )
    )
  );

// TODO Add a test
export const launchNewInstance: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(LAUNCH_NEW_INSTANCE)),
    mergeMap(action =>
      from(api.launchInstance(action.payload)).pipe(
        mergeMap(result =>
          concat(
            of(actions.launchNewInstanceSuccess(result)),
            of(instancesActions.fetchInstances())
          )
        ),
        catchError(err =>
          of(actions.launchNewInstanceFailure(action.payload, err))
        )
      )
    )
  );

// TODO Add test
export const fetchOrganizationUsage: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_ORGANIZATION_USAGE)),
    mergeMap(action =>
      from(state$.value.domains.domains).pipe(
        filter(
          domain => domain.organization === action.payload.organizationName
        ),
        mergeMap(domain => {
          return of(domainsActions.fetchDomainUsage(domain.domainName));
        })
      )
    )
  );

// TODO Add test
export const fetchDomainUsage: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(FETCH_DOMAIN_USAGE_SUCCESS)),
    mergeMap(action =>
      of(
        state$.value.domains.domains.find(
          domain => domain.domainName === action.payload.domainName
        )
      ).pipe(
        mergeMap(domain =>
          of(
            organizationsActions.fetchOrganizationUsageSuccess(
              domain!.organization
            )
          )
        )
      )
    )
  );

export default combineEpics(
  fetchDomainUsage,
  fetchOrganizations,
  fetchOrganizationsAndDeploymentsSuccess,
  fetchOrganizationsAndEdgeConfigsSuccess,
  fetchOrganizationUsage,
  init,
  launchNewInstance
);
