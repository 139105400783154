import { API } from "aws-amplify";

import Bottleneck from "bottleneck";

// TODO Remove this once stable
const useRealApi = true; // Used for debugging

// AWS has rate limits, so make sure they don't get exceeded. In particular, AWS Cognito
// is used to validate each request and is rate limited:
// https://docs.aws.amazon.com/cognito/latest/developerguide/limits.html
// After some testing, the following seems to work, 10 requests per second:
const limiter = new Bottleneck({
  minTime: 1000 / 10
});

export const API_NAME = "AdminAPI";

export type Version = {
  launcherVersion: string;
};

export const fetchVersion = (): Promise<Version> =>
  useRealApi
    ? limiter.schedule(() => API.get(API_NAME, "/version", {}))
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/version`, {
          method: "get",
          headers: {
            Accept: "Application/json, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export type InstanceState = "INIT" | "RUNNING" | "RESTARTING" | "ERROR";

export type InstanceResponse = {
  instanceId: string;
  organization: string | null;
  instanceState: InstanceState;
  stripeVersion: string | null;
  edgeVersion: string | null;
  launcherVersion: string | null;
  apiVersion: string | null;
  createdDate: number | null;
};

export const fetchInstances = (): Promise<InstanceResponse[]> =>
  useRealApi
    ? limiter.schedule(() => API.get(API_NAME, "/instances", {}))
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/instances`, {
          method: "get",
          headers: {
            Accept: "Application/json, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

// of means an observable of a certain value
// from builds an observable from a promise

export const deleteInstance = (instanceId: string): Promise<void | never> =>
  useRealApi
    ? limiter.schedule(() => API.del(API_NAME, `/instances/${instanceId}`, {}))
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/delete/${instanceId}`, {
          method: "delete",
          headers: {
            Accept: "text/plain, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return Promise.resolve(); // TODO Corentin: Removing this line gives an error.
          })
          .catch(err => {
            throw err;
          })
      );

export const launchInstance = (
  organization: string
): Promise<InstanceResponse | never> =>
  useRealApi
    ? limiter.schedule(() =>
        API.post(API_NAME, "/instances", {
          body: { organization }
        })
      )
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/instances`, {
          method: "post",
          headers: {
            Accept: "Application/json, */*",
            "content-type": "application/json"
          },
          body: JSON.stringify({ organization })
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export type Ec2InstanceState =
  | "pending"
  | "running"
  | "shutting-down"
  | "terminated"
  | "stopping"
  | "stopped";

export type Ec2InstanceResponse = {
  instanceId: string;
  instanceType: string;
  amiLaunchIndex: number;
  imageId: string;
  kernelId: string | null;
  keyName: string;
  launchTime: number | null;
  monitoring: {
    state: string;
  };
  placement: {
    availabilityZone: string;
    affinity: string | null;
    groupName: string;
    hostId: string | null;
    tenancy: string;
    spreadDomain: string | null;
  };
  platform: string | null;
  privateDnsName: string;
  privateIpAddress: string;
  productCodes: any[];
  publicDnsName: string;
  publicIpAddress: string;
  ramdiskId: string | null;
  state: {
    code: number;
    name: Ec2InstanceState;
  };
  stateTransitionReason: string;
  subnetId: string;
  vpcId: string;
  architecture: string;
  blockDeviceMappings: any[];
  clientToken: string;
  ebsOptimized: boolean;
  enaSupport: boolean;
  hypervisor: string;
  iamInstanceProfile: {
    arn: string;
    id: string;
  };
  instanceLifecycle: string | null;
  elasticGpuAssociations: any[];
  networkInterfaces: any[];
  rootDeviceName: string;
  rootDeviceType: string;
  securityGroups: any[];
  sourceDestCheck: boolean;
  spotInstanceRequestId: string | null;
  sriovNetSupport: string | null;
  stateReason: { code: string; message: string } | null;
  tags: any[];
  virtualizationType: string;
  cpuOptions: {
    coreCount: number;
    threadsPerCore: number;
  };
};

export const fetchEc2Instances = (): Promise<Ec2InstanceResponse[]> =>
  useRealApi
    ? limiter.schedule(() => API.get(API_NAME, "/ec2-instances", {}))
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/ec2-instances`, {
          method: "get",
          headers: {
            Accept: "Application/json, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export type DeploymentResponse = {
  organization: string;
  instanceId: string;
};

export const fetchDeployments = (): Promise<DeploymentResponse[]> =>
  useRealApi
    ? limiter.schedule(() => API.get(API_NAME, "/deployments", {}))
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/deployments`, {
          method: "get",
          headers: {
            Accept: "Application/json, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export const postDeployment = (
  organization: string,
  instanceId: string
): Promise<DeploymentResponse> =>
  useRealApi
    ? limiter.schedule(() =>
        API.post(API_NAME, "/deployments", {
          body: { organization, instanceId }
        })
      )
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/deployments`, {
          method: "post",
          headers: {
            Accept: "Application/json, */*",
            "content-type": "application/json"
          },
          body: JSON.stringify({ organization, instanceId })
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export type DomainResponse = {
  organization: string;
  domainName: string;
};

export const fetchDomains = (): Promise<DomainResponse[]> =>
  useRealApi
    ? limiter.schedule(() => API.get(API_NAME, "/domains", {}))
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/domains`, {
          method: "get",
          headers: {
            Accept: "Application/json, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export type OrganizationResponse = {
  name: string;
};

export const fetchOrganizations = (): Promise<OrganizationResponse[]> =>
  useRealApi
    ? limiter.schedule(() => API.get(API_NAME, "/organizations", {}))
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/organizations`, {
          method: "get",
          headers: {
            Accept: "Application/json, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export type EdgeConfigsResponse = {
  organization: string;
  name: string;
  version: number;
  config: string;
  caCertFilename: string | null;
  clientCertFilename: string | null;
  clientKeyFilename: string | null;
};

export const fetchEdgeConfigs = (): Promise<EdgeConfigsResponse[]> =>
  useRealApi
    ? limiter.schedule(() => API.get(API_NAME, "/edge-configs", {}))
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/edge-configs`, {
          method: "get",
          headers: {
            Accept: "Application/json, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export type DomainUsageResponse = {
  [dateStr: string]: number;
};

export const fetchDomainUsage = (
  domainName: string
): Promise<DomainUsageResponse> =>
  useRealApi
    ? limiter.schedule(() =>
        API.get(API_NAME, `/domains/${domainName}/usage`, {})
      )
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/domains/${domainName}/usage`, {
          method: "get",
          headers: {
            Accept: "Application/json, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export type UserResponse = {
  sub: string;
  email: string;
  username: string;
  createdDate: number;
  lastSignedIn: number;
  organizations: string[];
};

export const fetchUsers = (): Promise<UserResponse[]> =>
  useRealApi
    ? limiter.schedule(() => API.get(API_NAME, `/users`, {}))
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/users`, {
          method: "get",
          headers: {
            Accept: "Application/json, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export type AdminResponse = {
  sub: string;
};

export const fetchAdmins = (): Promise<AdminResponse[]> =>
  useRealApi
    ? limiter.schedule(() => API.get(API_NAME, `/admins`, {}))
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/admins`, {
          method: "get",
          headers: {
            Accept: "Application/json, */*"
          }
        })
          .then(response => {
            if (response.status !== 200) {
              return response.text().then(data => {
                throw new Error(data);
              });
            }
            return response.json();
          })
          .catch(err => {
            throw err;
          })
      );

export const makeAdmin = (sub: string): Promise<void | never> =>
  useRealApi
    ? limiter.schedule(() =>
        API.post(API_NAME, `/admins/${sub}`, {
          body: { sub }
        })
      )
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/admins/${sub}`, {
          method: "post",
          headers: {
            Accept: "Application/json, */*",
            "content-type": "application/json"
          },
          body: JSON.stringify({ sub })
        }).catch(err => {
          throw err;
        })
      );

export const revokeAdmin = (sub: string): Promise<void | never> =>
  useRealApi
    ? limiter.schedule(() =>
        API.post(API_NAME, `/admins/revoke/${sub}`, {
          body: { sub }
        })
      )
    : limiter.schedule(() =>
        fetch(`http://localhost:3005/admins/revoke/${sub}`, {
          method: "post",
          headers: {
            Accept: "Application/json, */*",
            "content-type": "application/json"
          },
          body: JSON.stringify({ sub })
        }).catch(err => {
          throw err;
        })
      );

// TODO Remove this:
// export const foo = (): Promise<string> =>
//   new Promise((resolve, reject) => {
//     // Do long/async task here, such as calling an API
//     if (Math.random() < 0.1) {
//       reject(new Error());
//     } else {
//       resolve("result goes here");
//     }
//   });
