import { Button, CircularProgress } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import React from "react";

import "./ProgressButton.css";

export type ProgressButtonProps = {
  disabled?: boolean;
  isPending?: boolean;
  didSucceed?: boolean;
} & ButtonProps;

const ProgressButton = ({
  disabled = false,
  isPending = false,
  didSucceed = false,
  ...props
}: ProgressButtonProps) => (
  <div className="ProgressButton">
    <Button disabled={isPending || disabled || didSucceed} {...props} />
    {isPending && (
      <CircularProgress size={20} className="ProgressButton-progress" />
    )}
  </div>
);

export default ProgressButton;
