import { ConnectedRouter } from "connected-react-router";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import Header from "../components/Header";
import IEWelcome from "../components/IEWelcome";
import ConfigsContainer from "../containers/ConfigsContainer";
import DomainsContainer from "../containers/DomainsContainer";
import { authThunks } from "../features/auth";
import { AdminUser } from "../features/auth/reducer";
import { RootState } from "../store";
import { history } from "../store/store";
import AuthenticatorContainer from "./AuthenticatorContainer";
import OrganizationsContainer from "./InstancesContainer";
import UsersContainer from "./UsersContainer";

import "./Root.css";

// TODO Create componet for all occurrences of <div className="chip">
// TODO Make ChipWithLabel its own component
// TODO Fix javascript console error: Please use `require("history").createBrowserHistory` instead of `require("history/createBrowserHistory")`. Support for the latter will be removed in the next major release.
// TODO Make all properties be read-only to prevent mutation.
// TODO If you delete the prod instance, organization.productionStatus is not updated. Probably need to requery orgs and deployments after delete success.
// TODO On Instances page, if an org has no instances in production, highlight this as an error, or:
// TODO Add report: Orgs that have running instances, but none of them are production.
// TODO Add report: Instance is running, but there is no deloyment. i.e. No config. So can't start an instance!
// TODO Add mock API for version call
// TODO Consider using an epic to calculate the highest edge node, and maintain a high watermark, even if all edge nodes with that version are deleted.
// TODO Add checkbox "Hide sandbox configs" to Configs screen.
// TODO Add button to delete all non-prod instances
// TODO If you add an instance for a user who doesn't have a config, get 404s with {"reason":"instance or deployment does not exist"}. Update API to add a config?
// TODO Clean up all classes to make sure they are all used
// TODO All reducers need to be able to delete items if they aren't included in the most recent fetch. Users does this.
// TODO Check behaviour of a non-admin user signs in.
// TODO Update README to explain deployment steps. i.e. Use same node.js as Jenkins. Remove `node_modules` and re-run `npm install` to see if `package-lock.json` is updated. Run `npm test`. Run `npm run build`.
// TODO Add mock REST server to this project

// TODO Corentin: Should all text be <Typography>
// TODO Corentin: Service worker?
// TODO Corentin: In tests, why use arrayContaining() instead of just []. Doesn't [] mean exactly what I expect?
// TODO Corentin: How to prevent fetching organizations when on the instances page?
// TODO Corentin: How to do {...props} when using Typescript?

type MatchParams = {
  orgName: string;
};

export type RootProps = {
  user: AdminUser;
  pathname: string;
  signOut: () => void;
};

class Root extends Component<RootProps> {
  public render() {
    const { signOut, user } = this.props;
    return (
      <ConnectedRouter history={history}>
        <div className="App">
          <Header user={user} signOut={signOut} />
          {navigator.appName === "Microsoft Internet Explorer" ||
          !!(
            navigator.userAgent.match(/Trident/) ||
            navigator.userAgent.match(/rv:11/)
          ) ? (
            <IEWelcome />
          ) : (
            <AuthenticatorContainer>
              <div className="App-content">
                <Switch>
                  <Route path="/instances" render={this.renderInstances} />
                  <Route path="/domains" render={this.renderDomains} />
                  <Route path="/users" render={this.renderUsers} />
                  <Route path="/configs" render={this.renderConfigs} />
                  <Route path="/" render={this.renderRedirect} />
                </Switch>
              </div>
            </AuthenticatorContainer>
          )}

          <footer>
            {/* <Typography variant="caption">
              Copyright © 2020 Teneft. All rights reserved.
            </Typography> */}
          </footer>
        </div>
      </ConnectedRouter>
    );
  }

  private renderInstances = (routerProps: RouteComponentProps<MatchParams>) => {
    return <OrganizationsContainer />;
  };

  private renderDomains = (routerProps: RouteComponentProps<MatchParams>) => {
    return <DomainsContainer />;
  };

  private renderUsers = (routerProps: RouteComponentProps<MatchParams>) => {
    return <UsersContainer />;
  };

  private renderConfigs = (routerProps: RouteComponentProps<MatchParams>) => {
    return <ConfigsContainer />;
  };

  private renderRedirect = (routerProps: RouteComponentProps<MatchParams>) => {
    return <Redirect to={`/instances`} />;
  };
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user!,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  signOut: authThunks.signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
