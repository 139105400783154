import { CircularProgress, Theme } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { CSSProperties, WithStyles } from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import React, { PureComponent } from "react";

import { Domain } from "../features/domains/models";
import { InstancesState } from "../features/instances";
import { compareVersions, Instance } from "../features/instances/models";
import { OrganizationsState } from "../features/organizations";
import { Organization } from "../features/organizations/models";
import { UsersState } from "../features/users";
import InstancesActions from "./InstancesActions";
import InstancesOrganizationRow from "./InstancesOrganizationRow";
import InstancesRow from "./InstancesRow";

import "./Instances.css";

export type InstancesProps = {
  instancesState: InstancesState;
  organizationsState: OrganizationsState;
  domains: Domain[];
  hasFetchedInitialDomains: boolean;
  usersState: UsersState;
  hasFetchedInitialAdmins: boolean;
  fetchOrganizationUsage: (organization: string, numDomains: number) => void;
  handleMakeProduction: (organization: string, instanceId: string) => void;
  handleLaunchNewInstance: (organization: string) => void;
  handleInstanceDelete: (instanceId: string) => void;
};

const NO_ORG = "_no_org";

export const TableCommon: CSSProperties = {
  borderBottom: "solid 1px #e3e3e3"
};

export const borderColor = "#e3e3e3";

export const TableCellCommon: CSSProperties = {
  textAlign: "left",
  paddingLeft: 8,
  paddingRight: 8,
  borderBottom: `solid 1px ${borderColor}`
};

export const TableCellCentered: CSSProperties = {
  textAlign: "center"
};

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    tableHeadRow: {
      /* height: tableRowHeight */
    },
    tableHeader: {
      ...TableCellCommon,
      position: "sticky",
      top: 0,
      zIndex: 10,
      backgroundColor: "#f3f3f3",
      boxShadow: `inset 0 1px 0 ${borderColor}, inset 0 -1px 0 ${borderColor}`,
      color: "rgba(0, 0, 0, 0.75)",
      whiteSpace: "nowrap"
    },
    centered: { ...TableCellCentered }
  });

/**
 * Compare function for sorting organizations.
 */
export function compareOrganizations(a: Organization, b: Organization) {
  return a.name.localeCompare(b.name);
}

/**
 * Compare function for sorting instances table.
 * Sort by organization, then version.
 */
function compareInstances(a: Instance, b: Instance) {
  const aOrg = a.organization ? a.organization.toLowerCase() : "";
  const bOrg = b.organization ? b.organization.toLowerCase() : "";
  if (aOrg < bOrg) {
    return -1;
  } else if (aOrg > bOrg) {
    return 1;
  } else {
    // From https://stackoverflow.com/a/45138781/1360592
    return (a.edgeVersion || "").localeCompare(b.edgeVersion || "", undefined, {
      numeric: true,
      sensitivity: "base"
    });
  }
}

class Instances extends PureComponent<
  InstancesProps & WithStyles<typeof styles>
> {
  private prevOrg: string | null = null;

  private instancesSorted: Instance[] = [];

  private organizationsWithRunningInstances = new Set<string>();

  public render() {
    const {
      instancesState,
      organizationsState,
      handleMakeProduction,
      handleLaunchNewInstance,
      handleInstanceDelete,
      domains,
      hasFetchedInitialDomains,
      usersState,
      hasFetchedInitialAdmins,
      classes
    } = this.props;
    const organizations = this.props.organizationsState.byName;
    const instances = this.props.instancesState.byInstanceId;

    this.instancesSorted.length = 0;
    Object.keys(instances).forEach(instanceId => {
      const instance = instances[instanceId];
      this.instancesSorted.push(instance);
    });
    this.instancesSorted.sort(compareInstances);

    this.organizationsWithRunningInstances.clear();

    this.prevOrg = null;

    const highestEdgeVersion = this.highestEdgeVersion;
    return (
      <div className="Instances">
        <InstancesActions
          organizationsState={organizationsState}
          instancesState={instancesState}
          hasFetchedInitialDomains={hasFetchedInitialDomains}
          handleMakeProduction={handleMakeProduction}
          handleLaunchNewInstance={handleLaunchNewInstance}
          handleInstanceDelete={handleInstanceDelete}
          handleFetchUsageForAllDomains={this.handleFetchUsageForAllDomains}
        />
        <div className="Instances-data">
          <Table>
            <TableHead>
              <TableRow
                classes={{
                  root: classes.tableHeadRow
                }}
              >
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader)
                  }}
                >
                  org
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader)
                  }}
                >
                  instance id
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader, classes.centered)
                  }}
                >
                  prod
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader)
                  }}
                >
                  state
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader)
                  }}
                >
                  ec2 state
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader)
                  }}
                >
                  public ip
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader)
                  }}
                >
                  stripe
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader, classes.centered)
                  }}
                  colSpan={2}
                >
                  edge version
                  <br />
                  <span className="highestEdgeVersion">
                    {highestEdgeVersion && `(highest: ${highestEdgeVersion})`}
                  </span>
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader, classes.centered)
                  }}
                >
                  portal.java
                  <br />
                  version
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader, classes.centered)
                  }}
                >
                  api
                  <br />
                  version
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader)
                  }}
                >
                  created date
                </TableCell>
                <TableCell
                  classes={{
                    root: classNames(classes.tableHeader, classes.centered)
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {instancesState.hasFetchedInitialInstances &&
                organizationsState.hasFetchedInitialOrganizations &&
                this.instancesSorted.map(instance => {
                  const organization = instance.organization
                    ? organizations[instance.organization]
                    : null;

                  const isSameAsPreviousOrg =
                    (instance.organization &&
                      instance.organization === this.prevOrg) ||
                    (instance.organization === null && this.prevOrg === NO_ORG);
                  this.prevOrg = instance.organization || NO_ORG;

                  if (organization && !isSameAsPreviousOrg) {
                    this.organizationsWithRunningInstances.add(
                      organization.name
                    );
                  }

                  const user = organization
                    ? usersState.byUsername[organization.name]
                    : null;

                  let organizationRow;
                  if (!isSameAsPreviousOrg) {
                    organizationRow = (
                      <InstancesOrganizationRow
                        organization={organization}
                        domains={
                          organization
                            ? domains.filter(
                                domain =>
                                  domain.organization === organization.name
                              )
                            : null
                        }
                        user={user}
                        hasFetchedInitialAdmins={hasFetchedInitialAdmins}
                        handleLaunchNewInstance={handleLaunchNewInstance}
                      />
                    );
                  }

                  const isOutdated =
                    compareVersions(
                      instance.edgeVersion,
                      highestEdgeVersion
                    ) === -1;

                  return (
                    <React.Fragment
                      key={`${organization ? organization.name : NO_ORG}-${
                        instance.instanceId
                      }`}
                    >
                      {organizationRow}
                      <InstancesRow
                        instance={instance}
                        organization={organization!}
                        isSameAsPreviousOrg={isSameAsPreviousOrg}
                        isOutdated={isOutdated}
                        handleMakeProduction={handleMakeProduction}
                        handleInstanceDelete={handleInstanceDelete}
                      />
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
          {!(
            instancesState.hasFetchedInitialInstances &&
            organizationsState.hasFetchedInitialOrganizations
          ) && (
            <div className="Instances-progress">
              <CircularProgress size={64} />
            </div>
          )}
        </div>
      </div>
    );
  }

  private fetchOrganizationUsage = (organizationName: string) => {
    const numDomains = this.props.domains.filter(
      d => d.organization === organizationName
    ).length;
    this.props.fetchOrganizationUsage(organizationName, numDomains);
  };

  private handleFetchUsageForAllDomains = () => {
    this.organizationsWithRunningInstances.forEach((o, index) => {
      this.fetchOrganizationUsage(o);
    });
  };

  private get highestEdgeVersion() {
    return this.instancesSorted
      .map(instance => instance.edgeVersion)
      .filter(edgeVersion => !!edgeVersion)
      .reduce(
        (highest, edgeVersion) =>
          !highest || compareVersions(edgeVersion, highest) === 1
            ? edgeVersion
            : highest,
        null
      );
  }
}

export default withStyles(styles)(Instances);
