import { combineReducers } from "redux";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import {
  FETCH_DOMAIN_USAGE,
  FETCH_DOMAIN_USAGE_FAILURE,
  FETCH_DOMAIN_USAGE_SUCCESS,
  FETCH_DOMAINS_FAILURE,
  FETCH_DOMAINS_SUCCESS
} from "./constants";
import { Domain } from "./models";

export type DomainsAction = ActionType<typeof actions>;

export type DomainsState = {
  domains: Domain[];
  hasFetchedInitialDomains: boolean;
};

export default combineReducers<DomainsState, DomainsAction>({
  domains: (state = [], action) => {
    switch (action.type) {
      case FETCH_DOMAINS_SUCCESS:
        const domains: Domain[] = [];
        action.payload.forEach(dr => {
          // If the domain was previously fetched, then update its data, otherwise add it.
          const domain = state.find(
            d =>
              d.organization === dr.organization &&
              d.domainName === dr.domainName
          );
          if (domain) {
            domains.push({ ...domain, ...dr });
          } else {
            domains.push({
              ...dr,
              usage: null,
              isFetchingUsage: false
            });
          }
        });
        return domains;

      case FETCH_DOMAINS_FAILURE:
        return [];

      case FETCH_DOMAIN_USAGE:
        const domains2: Domain[] = [];
        state.forEach(domain => {
          if (domain.domainName === action.payload) {
            domains2.push({ ...domain, isFetchingUsage: true });
          } else {
            domains2.push({ ...domain });
          }
        });
        return domains2;

      case FETCH_DOMAIN_USAGE_SUCCESS:
        const domains3: Domain[] = [];
        state.forEach(domain => {
          if (domain.domainName === action.payload.domainName) {
            domains3.push({
              ...domain,
              usage: action.payload.domainUsageResponse,
              isFetchingUsage: false
            });
          } else {
            domains3.push({ ...domain });
          }
        });
        return domains3;

      case FETCH_DOMAIN_USAGE_FAILURE:
        const domains4: Domain[] = [];
        state.forEach(domain => {
          if (domain.domainName === action.payload.domainName) {
            domains4.push({ ...domain, isFetchingUsage: false });
          } else {
            domains4.push({ ...domain });
          }
        });
        return domains4;

      default:
        return state;
    }
  },
  hasFetchedInitialDomains: (state = false, action) => {
    switch (action.type) {
      case FETCH_DOMAINS_SUCCESS:
        return true;
      default:
        return state;
    }
  }
});
